import React, { useContext, useEffect, useState } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'moment/locale/es';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { Container, Dialog, DialogTitle, DialogContent, TextField, DialogActions, Button, Snackbar, Alert, MenuItem } from '@mui/material';

import InventoryContext from '../../../contexts/InventoryContext';
import { ReservationAttributesInterface, ClientAttributesInterface } from '../../../interfaces/models';

// Configuración de Moment para el calendario
moment.locale('es');
const localizer = momentLocalizer(moment);

// Define the CalendarEvent type based on the structure of reservation data
interface CalendarEvent {
  id: number;
  title: string;
  start: Date;
  end: Date;
  allDay?: boolean;
  idCliente?: number;
  notas?: string;
}

const Reservations: React.FC = () => {
  const context = useContext(InventoryContext);
  if (!context) {
    throw new Error('Reservations debe usarse dentro de un InventoryProvider');
  }

  const { tableData, addItem, updateItem, deleteItem, usuarioActual } = context;

  const [reservations, setReservations] = useState<CalendarEvent[]>([]);
  const [clients, setClients] = useState<ClientAttributesInterface[]>([]);
  const [selectedEvent, setSelectedEvent] = useState<CalendarEvent | null>(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [snackbar, setSnackbar] = useState<{ open: boolean; message: string; severity: 'success' | 'error' }>({ open: false, message: '', severity: 'success' });
  const [formFields, setFormFields] = useState({
    idCliente: '',
    notas: '',
    fecha: new Date(),
  });

  // Snackbar function
  const showSnackbar = (message: string, severity: 'success' | 'error') => {
    setSnackbar({ open: true, message, severity });
  };

  // Function to load reservations from context
  const fetchReservations = () => {
    const response = tableData.reservations;
    
    if (Array.isArray(response)) {
      const reservasMapeadas = response.map((reserva: ReservationAttributesInterface) => ({
        id: reserva.idReserva ?? 0,
        title: reserva.notas || 'Reserva',
        start: new Date(reserva.fecha!),
        end: new Date(new Date(reserva.fecha!).getTime() + 60 * 60 * 1000), // 1 hour duration
        idCliente: reserva.idCliente,
      }));
      setReservations(reservasMapeadas);
    } else {
      console.warn("Reservations data is not an array or is undefined.");
      setReservations([]);
    }
  };

  // Function to load clients from context
  const fetchClients = () => {
    const response = tableData.clients;

    if (Array.isArray(response)) {
      setClients(response);
    } else {
      console.warn("Client data is not an array or is undefined.");
      setClients([]);
    }
  };

  // Handle date selection in the calendar
  const handleSelectSlot = (slotInfo: { start: Date; end: Date }) => {
    setFormFields({ idCliente: '', notas: '', fecha: slotInfo.start });
    setSelectedEvent(null);
    setOpenDialog(true);
  };

  // Handle event selection in the calendar
  const handleSelectEvent = (event: CalendarEvent) => {
    setSelectedEvent(event);
    setFormFields({
      idCliente: event.idCliente ? event.idCliente.toString() : '',
      notas: event.title,
      fecha: event.start,
    });
    setOpenDialog(true);
  };

  // Handle form submission to add or update reservations
  const handleFormSubmit = async () => {
    const newReservation: ReservationAttributesInterface = {
      idCliente: parseInt(formFields.idCliente, 10),
      fecha: formFields.fecha,
      notas: formFields.notas,
      idSalon: 0 // TODO: Adjust according to the salon logic
    };

    const response = selectedEvent
      ? await updateItem('reservations', selectedEvent.id as number, newReservation)
      : await addItem('reservations', newReservation);

    if (response.status) {
      fetchReservations();
      showSnackbar(`Reserva ${selectedEvent ? 'actualizada' : 'agregada'} exitosamente.`, 'success');
      setOpenDialog(false);
    } else {
      showSnackbar(`Error al ${selectedEvent ? 'actualizar' : 'agregar'} la reserva: ${response.answer}`, 'error');
    }
  };

  // Handle reservation deletion
  const handleDeleteReservation = async () => {
    if (selectedEvent) {
      const response = await deleteItem('reservations', selectedEvent.id as number);
      if (response.status) {
        fetchReservations();
        showSnackbar('Reserva eliminada exitosamente.', 'success');
        setOpenDialog(false);
      } else {
        showSnackbar(`Error al eliminar la reserva: ${response.answer}`, 'error');
      }
    }
  };

  // Load reservations and clients when component mounts or tableData changes
  useEffect(() => {
    fetchReservations();
    fetchClients();
  }, [tableData]);

  return (
    <Container>
      <Calendar
        localizer={localizer}
        events={reservations}
        startAccessor="start"
        endAccessor="end"
        style={{ height: 600, margin: '20px 0' }}
        selectable
        onSelectEvent={handleSelectEvent}
        onSelectSlot={handleSelectSlot}
        views={['month', 'week', 'day', 'agenda']}
        messages={{
          month: 'Mes',
          day: 'Día',
          week: 'Semana',
          today: 'Hoy',
          previous: 'Anterior',
          next: 'Siguiente',
        }}
      />

      {/* Dialog for adding/editing reservation */}
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>{selectedEvent ? 'Editar Reserva' : 'Nueva Reserva'}</DialogTitle>
        <DialogContent>
          <TextField
            select
            label="Cliente"
            fullWidth
            margin="normal"
            value={formFields.idCliente}
            onChange={(e) => setFormFields({ ...formFields, idCliente: e.target.value })}
          >
            {clients.map((client) => (
              <MenuItem key={client.idClient} value={client.idClient}>
                {client.nombre}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            label="Notas"
            type="text"
            fullWidth
            margin="normal"
            value={formFields.notas}
            onChange={(e) => setFormFields({ ...formFields, notas: e.target.value })}
          />
          <TextField
            label="Fecha y hora de inicio"
            type="datetime-local"
            fullWidth
            margin="normal"
            value={moment(formFields.fecha).format('YYYY-MM-DDTHH:mm')}
            onChange={(e) => setFormFields({ ...formFields, fecha: new Date(e.target.value) })}
          />
        </DialogContent>
        <DialogActions>
          {selectedEvent && (
            <Button onClick={handleDeleteReservation} color="error">
              Eliminar
            </Button>
          )}
          <Button onClick={() => setOpenDialog(false)} color="inherit">
            Cancelar
          </Button>
          <Button onClick={handleFormSubmit} color="primary">
            {selectedEvent ? 'Actualizar' : 'Agregar'}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar for success/error messages */}
      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={() => setSnackbar({ ...snackbar, open: false })}>
        <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity} variant="filled">
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default Reservations;
