import React, { useEffect, useState, ReactNode, useContext } from 'react';
import {
  Paper,
  Grid,
  SelectChangeEvent,
} from '@mui/material';
import { AsistenciaAttributes, SaleAttributesInterface, TicketAttributesInterface, 
  UserAttributes, ServiceProductAttributesInterface, ComisionInterface } from '../../../../interfaces/models';

import './Venta.css';

import { VentaDerecha } from './VentaDerecho';
import { VentaCentro } from './VentaCentro';
import { VentaIzquierdo } from './VentaIzquierdo';
import InventoryContext from '../../../../contexts/InventoryContext';

//TODO: agregar confirmacion visual del ticket creado realiaza exitosamente
interface Ticket {
  user_id_sale_made: number;
  id_service_product: number;
  oper: string;
  descripcion: string;
  cantidad: number;
  precioUnitario: number;
  tipoComision: number;
  comisionActiva: boolean;
  costoComisionAPagar: number;
  cantidadEnvase: string;
}

const Venta = () => {
  const context = useContext(InventoryContext);

  if (!context) {
    throw new Error('UserList must be used within an InventoryProvider');
  }
  const { tableData, deleteItem, addItem, updateItem } = context;



  const [tickets, setTickets] = useState<Ticket[]>([]);

  const [data, setData] = useState<ServiceProductAttributesInterface[]>([]);
  const [usuarios, setUsuarios] = useState<UserAttributes[]>([]);

  const [openDialog, setOpenDialog] = useState(false);
  const [observaciones, setObservaciones] = useState('');
  const [usuarioSeleccionado, setUsuarioSeleccionado] = useState<number | null>(null);
  const [comisionActual, setComisionActual] = useState<ComisionInterface[]>([]);
  const [editingValues, setEditingValues] = useState<{ [key: number]: string }>({});

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleSaveObservaciones = () => {
    // Aquí puedes agregar lógica adicional si es necesario
    console.log(observaciones); // Para propósitos de depuración
    setOpenDialog(false);
  };

  const calculateTotal = (cantidad: number, precioUnitario: number) => cantidad * precioUnitario;

  const [grandTotal, setGrandTotal] = useState<number>(0);
  const calculateGrandTotal = (data: Ticket[]) => {
    // Calcula el total como lo hacías antes
    const total = data.reduce((accumulator, item) => accumulator + calculateTotal(item.cantidad, item.precioUnitario), 0);
    
    // Actualiza el estado para guardar el total calculado
    if(grandTotal != total){
      setGrandTotal(total);
    }
    // Retorna el total calculado como de costumbre
    return total;
  };



  const addToTicket = (productData: ServiceProductAttributesInterface) => {
    console.log("click en producto: ", productData);
  
    const extraInfo = productData.type == 'servicio'? 1000 : 0;
    const usuarioIdPorDefecto = usuarioSeleccionado ?? (usuarios.length > 0 ? usuarios[0].idUser! : null);
    if (usuarioIdPorDefecto === null) {
      console.error("No hay usuarios disponibles para seleccionar.");
      return;
    }
  
    const existingProductIndex = tickets.findIndex(ticket => ticket.descripcion === productData.detalles);
    if (existingProductIndex !== -1) {
      // Producto existe, actualizar cantidad si hay suficiente stock
      const updatedTickets = tickets.map((ticket, index) => {
        if (index === existingProductIndex) {
          if (ticket.cantidad < productData.quantity! + extraInfo) {
            return { ...ticket, cantidad: ticket.cantidad + 1 };
          } else {
            alert("No hay suficiente stock para este producto.");
            // Aquí podrías mostrar un mensaje más sofisticado que un alert.
          }
        }
        return ticket;
      });
      setTickets(updatedTickets);
    } else {
      // Producto no existe, agregar nuevo
      console.log("mi producto es: ",productData)
      if (productData.quantity! + extraInfo> 0) {
        setTickets([...tickets, {
          user_id_sale_made: usuarioIdPorDefecto,
          oper: usuarioIdPorDefecto.toString(),
          descripcion: productData.detalles!,
          cantidad: 1,
          precioUnitario: productData.salesPrice!,
          id_service_product: productData.idServiceProduct!, // Asegúrate de que esta línea esté presente y correcta
          tipoComision: 3,
          comisionActiva: usuarios[0]?.comision!,
          costoComisionAPagar: 0,//aqui poner cuanto es el valor y con tipo comision se calcula//TODO
          cantidadEnvase: ""
        }]);
        // ... Resto de tu código para añadir nuevo ticket ...
      } else {
        alert("Este producto no tiene stock.");
      }

    }
  };
  
  const removeTicket = (indexToRemove: number) => {
    setTickets(tickets.filter((_, index) => index !== indexToRemove));
  }; 

   // Funciones para filtrar la lista en base al nombre
  const fetchUsuarios = async () => {
    try {
    const asistenciasActivas: AsistenciaAttributes[] = tableData.asistencias.filter(asistencia => !asistencia.salida);//await asistenciaReadAllActive();
    // Crear un conjunto (set) con los idUser de asistencias activas convertidos a string
    const activeUserIds = new Set(asistenciasActivas.map(asistencia => asistencia.idUser?.toString()));

    // Filtrar los usuarios que tienen asistencias activas
    const usuariosActivos : UserAttributes[] = tableData.usuarios.filter((user: UserAttributes) => activeUserIds.has(user.idUser?.toString()));



    // Mapear los usuarios activos para la interfaz de usuario

    const usuariosMapeados = usuariosActivos.map(user => ({
      idUser: user.idUser!,  // Default to '0' if idUser is undefined
      firstName: user.firstName || 'N/A',      // Default to 'N/A' if firstName is undefined
      lastName: user.lastName || 'N/A',        // Default to 'N/A'
      idCard: user.idCard?.toString() || 'N/A',    // Default to 'N/A'
      mobileNumber: user.mobileNumber?.toString() || 'N/A', // Default to 'N/A'
      comision: user.comision!, // Default to false
    }));
    

    setUsuarios(usuariosMapeados);

      // Seleccionar automáticamente el primer usuario como usuarioSeleccionado, si la lista no está vacía
      if (usuariosMapeados.length > 0) {
        const selectedUserId = usuariosMapeados[0].idUser!;
        setUsuarioSeleccionado(selectedUserId);
      }
    } catch (error) {
      console.error('Error al cargar los usuarios:', error);
    }
  };

  useEffect(()=>{
    const fetchDatos = async () => {
      const response = tableData.serviceProducts //await serviceProductReadAll();
      console.log("response all products: ",response)
      setData(response)
      console.log("data: ",data)
      if(comisionActual.length === 0) {
        //const responseComision = await api.get('/comisionReadAll');
        const responseComision = tableData.comisiones //await comisionesReadAll();
        console.log("mis comisiones son: ", responseComision);
        const dataComisionMapeada: ComisionInterface[] = responseComision.map((data: any) => ({
          idComision: data.idComision,
          comision: data.comision,
          tipo: data.tipo,
        }))
        setComisionActual(dataComisionMapeada)
      }
    };
    fetchDatos();
    fetchUsuarios();
  },[])
  // Actualiza el operario seleccionado en un ticket específico
  const updateTicketOper = (index: number, usuarioId: number) => {
    const usuarioEncontrado = usuarios.find(usuario => usuario.idUser === usuarioId);  
    const currentComision = usuarioEncontrado ? usuarioEncontrado.comision : false;
    const updatedTickets = tickets.map((ticket, idx) => {
      if (idx === index) {
        return { ...ticket, oper: usuarioId.toString(), user_id_sale_made: usuarioId, comisionActiva: currentComision!};
      }
      return ticket;
    });
    setTickets(updatedTickets);
  };
  

  //LOGICA BOOTN EFECTIVO
      const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

      const handleOpenConfirmDialog = () => {
        setOpenConfirmDialog(true);
      };

      const handleCloseConfirmDialog = () => {
        setOpenConfirmDialog(false);
      };

      const handleConfirmVenta = () => {
        // Declaramos el tipo del array como string[]
        let productosExcedidos: string[] = [];
        console.log("productosExcedidos antes: ", productosExcedidos)
      
        // Verifica que hay suficiente stock para cada producto en el ticket
        const isStockSufficient = tickets.every(ticket => {
          const productData = data.find(product => product.idServiceProduct === ticket.id_service_product);
          if (!productData || (ticket.cantidad > productData.quantity! && productData.type=='producto')) {
            productosExcedidos.push(ticket.descripcion);
            return false;
          }
          return true;
        });
        console.log("productosExcedidos: ", productosExcedidos)
        if (productosExcedidos.length > 0) {
          // Crear un mensaje con los nombres de los productos excedidos
          let mensaje = "Los siguientes productos exceden la cantidad disponible en stock: ";
          mensaje += productosExcedidos.join(", ");
          alert(mensaje);
          // No continúes con la venta
          return;
        }
      
        // Si hay suficiente stock, procede a guardar el ticket y las ventas asociadas
        prepareAndSaveTicket();
        setOpenConfirmDialog(false);
        // ... Resto de tu lógica ...
      };
      
      
      

    

  // LOGICA GUARDAR TICKET 
  const prepareAndSaveTicket = async () => {
    // Asumiendo que tienes un estado o forma de obtener el id del supervisor y el usuario que modifica
    const idSupervisor = 1; // Deberías obtener este valor de la sesión del usuario o un estado
    const userIdModification = 2; // Igual que idSupervisor, debe venir de la sesión o estado TODO cambiar al usuario actual
  
    const ticketData : TicketAttributesInterface = {
      idSupervisor: idSupervisor,
      totalMountSale: calculateGrandTotal(tickets),
      idListProductServices: tickets.map(ticket => ticket.user_id_sale_made), // Asegúrate de tener este id en tu estado de tickets
      paymentType: "Cash", // O "QR", dependiendo de la lógica que quieras implementar
      notes: observaciones,
      valid: true, // Asumiendo que quieres que todos los tickets sean válidos por defecto
      userIdModification: userIdModification
    };
  
    // Llamar a la API para guardar los datos
    try {
      const ticketResponseBody = await addItem('tickets',ticketData) //await ticketCreate(ticketData); //api.post('/ticketsCreate', ticketData);
      console.log("yeah ticket created: ",ticketResponseBody);
      if (ticketResponseBody?.answer?.idTicket) {
        const idTicket = ticketResponseBody.answer.idTicket;
        console.log("ticket value is: ", tickets)
        // Iterar sobre cada producto en el ticket para crear las ventas
        for (const ticket of tickets) {
          let comision = 0;
          const cost = ticket.precioUnitario * ticket.cantidad; // Suponiendo que el costo total es precio unitario por cantidad
        
          // Calcular la comisión basado en el tipo
          switch (ticket.tipoComision) {
            case 1:
              comision = ticket.costoComisionAPagar * cost / 100;
              break;
            case 0:
              comision = ticket.costoComisionAPagar * ticket.cantidad;
              break;
            case 3:
              comision = 0;
              break;
          }

          const saleData : SaleAttributesInterface= {
            idUser: ticket.user_id_sale_made, // Este es el ID del usuario seleccionado para la venta
            idServiceProduct: ticket.id_service_product,
            quantity: ticket.cantidad,
            payment: false, // Por defecto en false
            idTicket: idTicket, // El ID del ticket creado
            tipoComision: ticket.tipoComision,
            cantidadPago: ticket.costoComisionAPagar,
            valorComisionAPagar: comision,
            valorProductoIndividual: ticket.precioUnitario,
          };
  
          try {
            await addItem('sales',saleData); //await saleCreate(saleData)
            console.log("Venta creada con éxito para el producto: ", ticket.descripcion);
          
            // Encuentra el producto vendido en el estado 'data' para actualizar su stock
            const productIndex = data.findIndex(product => product.idServiceProduct === ticket.id_service_product && product.type === 'producto');
            if (productIndex !== -1) {
              const producto = data[productIndex];
              const nuevaCantidad = producto.quantity! - ticket.cantidad;
          
              console.log("datos para actualizar producto: ");
              console.log("id_service_product: ", producto.idServiceProduct);
              console.log("quantity: ", nuevaCantidad);
          
              // Corrección aquí: uso correcto de la interpolación de la variable en la URL
              await updateItem('serviceProducts',producto.idServiceProduct!,{idServiceProduct: producto.idServiceProduct,quantity: nuevaCantidad}) //await serviceProductUpdate({idServiceProduct: producto.idServiceProduct,quantity: nuevaCantidad})
              /*await api.put(`/servicesProductsUpdate?idServiceProduct=${producto.id_service_product}`, {
                quantity: nuevaCantidad
              });*/
          
              // Actualizar el estado local 'data' para reflejar el nuevo stock
              const newData = [...data];
              newData[productIndex] = { ...producto, quantity: nuevaCantidad };
              setData(newData);
            }
          } catch (error) {
            console.error('Error creando venta para el producto: ', ticket.descripcion, error);
            // Aquí podrías manejar errores específicos de cada venta
          }
          
        }
        setTickets([])
        // Aquí podrías mostrar alguna alerta o mensaje de éxito global después de procesar todas las ventas
      } else {
        // Manejar caso en el que el ticket no se crea correctamente
        console.error('No se pudo crear el ticket correctamente');
      }
      // Mostrar alguna alerta o mensaje de éxito aquí
    } catch (error) {
      console.error('Error al crear el ticket:', error);
      // Mostrar alguna alerta o mensaje de error aquí
    }
  };

  
  const handleTipoComisionChange = (event: SelectChangeEvent<string>, index: number) => {
    // Convertimos el valor a número para obtener el índice, excepto cuando el valor es "NA"
    const comisionIndex = event.target.value !== "NA" ? Number(event.target.value) : null;
    console.log(`Cambiando tipoComision para el índice ${comisionIndex}:`, comisionIndex);
    console.log(`Cambiando tipoComision para el índice ${index}:`, index);
    const updatedTickets = tickets.map((ticket, idx) => {
      if (idx === index) {
        if (comisionIndex !== null) {
          const comisionSeleccionada = comisionActual[comisionIndex];
          console.log(`Cambiando tipoComision para el comisionSeleccionada ${comisionSeleccionada}:`, comisionSeleccionada);
          let nuevoTipoComision = 0; // Por defecto, aunque esto debería ser manejado de manera diferente si 'NA' necesita un valor específico.
          if (comisionSeleccionada.tipo === "%") {
            nuevoTipoComision = 1;
          } else if (comisionSeleccionada.tipo === "efectivo") {
            nuevoTipoComision = 2;
          }
          return {
            ...ticket,
            tipoComision: nuevoTipoComision,
            costoComisionAPagar: comisionSeleccionada.comision!,
          };
        } else {
          // Aquí manejamos el caso "NA", ajusta según necesites
          return { ...ticket, tipoComision: 3, costoComisionAPagar: 0 };
        }
      }
      return ticket;
    });
    console.log("nuevos tickets: ",updatedTickets)
    setTickets(updatedTickets);
    //REVISAR TODO
    const newTickets = [...tickets];
    const selectedComisionIndex = parseInt(event.target.value);
    newTickets[index].tipoComision = selectedComisionIndex;
    setTickets(newTickets);
  };


  const handleCantidadChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const newValue = event.target.value;
    setEditingValues(prev => ({ ...prev, [index]: newValue }));
  };
  
  
  const handleCantidadBlur = (event: React.FocusEvent<HTMLInputElement>, index: number) => {
    const newValue = event.target.value;
    const nuevaCantidad = parseInt(newValue, 10);
    
    const updatedTickets = tickets.map((ticket, idx) => {
      if (idx === index) {
        return { ...ticket, cantidad: isNaN(nuevaCantidad) || nuevaCantidad < 1 ? 1 : nuevaCantidad };
      }
      return ticket;
    });
    setTickets(updatedTickets);
    setEditingValues(prev => {
      const { [index]: _, ...rest } = prev;
      return rest;
    });
  };
  
  


  return (
    <Paper className="controlOperarioContainer" sx={{ padding: 2 }}>
    <Grid 
      container 
      spacing={2} 
      justifyContent="space-between" 
      alignItems="flex-start"
    >
      {/* Columna Izquierda (VentaCentro y VentaIzquierdo) */}
      <Grid item xs={12} md={7} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}>
        {/* Encabezado central con los íconos y el ticket */}
        <VentaCentro
          handleOpenDialog={handleOpenDialog}
          handleOpenConfirmDialog={handleOpenConfirmDialog}
          openConfirmDialog={openConfirmDialog}
          handleCloseConfirmDialog={handleCloseConfirmDialog}
          grandTotal={grandTotal}
          handleConfirmVenta={handleConfirmVenta}
          openDialog={openDialog}
          handleCloseDialog={handleCloseDialog}
          observaciones={observaciones}
          setObservaciones={setObservaciones}
          usuarios={usuarios}
          handleSaveObservaciones={handleSaveObservaciones}
          tickets={tickets}
          calculateGrandTotal={calculateGrandTotal}
        />
        
        {/* Detalle del ticket */}
        <VentaIzquierdo
          tickets={tickets}
          updateTicketOper={updateTicketOper}
          usuarios={usuarios}
          handleTipoComisionChange={handleTipoComisionChange}
          comisionActual={comisionActual}
          handleCantidadChange={handleCantidadChange}
          handleCantidadBlur={handleCantidadBlur}
          calculateTotal={calculateTotal}
          removeTicket={removeTicket}
          calculateGrandTotal={calculateGrandTotal}
          observaciones={observaciones}
          editingValues={editingValues}
          setEditingValues={setEditingValues}
        />
      </Grid>
  
      {/* Columna Derecha (VentaDerecha) */}
      <Grid item xs={12} md={5}>
        <VentaDerecha
          addToTicket={addToTicket}
          data={data}
        />
      </Grid>
    </Grid>
  </Paper>
  
  );
  
  
};

export default Venta;
