import React, { useState, useContext, useEffect } from 'react';
import { Container, Grid, Button, Snackbar, Alert } from '@mui/material';

import { FormComponentsProvider } from '../../../services/ThemeProvider';
import InputTable from '../../../services/InputTable';
import DataTable from '../../../services/DataTable';
import InventoryContext from '../../../contexts/InventoryContext';
import { ClientAttributesInterface } from '../../../interfaces/models';

const Clientes: React.FC = () => {
  const context = useContext(InventoryContext);
  if (!context) {
    throw new Error('Clientes debe usarse dentro de un InventoryProvider');
  }

  const { tableData, deleteItem, addItem, updateItem } = context;

  // Estados para los campos del formulario de cliente
  const [nombre, setNombre] = useState<string>('');
  const [celular, setCelular] = useState<string>('');
  const [clientes, setClientes] = useState<ClientAttributesInterface[]>([]);
  const [snackbar, setSnackbar] = useState<{ open: boolean; message: string; severity: "success" | "error" }>({ open: false, message: '', severity: 'success' });

  // Función para mostrar el snackbar
  const showSnackbar = (message: string, severity: "success" | "error") => {
    setSnackbar({ open: true, message, severity });
  };

// Función para cargar clientes desde el contexto
const fetchClientes = () => {
  const response = tableData.clients;

  // Verificamos si `response` es un array
  if (Array.isArray(response)) {
    const clientesMapeados = response.map((client: ClientAttributesInterface) => ({
      idClient: client.idClient || 0, // Valor por defecto en caso de que idClient sea undefined
      nombre: client.nombre || '',     // Valor por defecto en caso de que nombre sea undefined
      celular: client.celular || '',   // Valor por defecto en caso de que celular sea undefined
    }));
    setClientes(clientesMapeados);
  } else {
    console.warn("No hay datos de clientes disponibles o el formato de los datos es incorrecto.");
    setClientes([]); // Define `clientes` como un array vacío si no hay datos
  }
};



  // Validar y agregar un nuevo cliente
  const handleAddClient = async () => {
    if (!nombre || !celular) {
      showSnackbar("El nombre y el celular son obligatorios.", "error");
      return;
    }

    const newClient: ClientAttributesInterface = {
      nombre,
      celular,
    };

    const response = await addItem('clients', newClient);
    if (response.status) {
      setNombre('');
      setCelular('');
      fetchClientes(); // Actualiza la lista después de agregar un cliente
      showSnackbar("Cliente agregado exitosamente.", "success");
    } else {
      showSnackbar(`Error al agregar el cliente: ${response.answer}`, "error");
    }
  };

  // Función para eliminar un cliente
  const handleDeleteClient = async (clientId: number) => {
    const response = await deleteItem('clients', clientId);
    if (response.status) {
      fetchClientes(); // Actualiza la lista después de eliminar un cliente
      showSnackbar("Cliente eliminado exitosamente.", "success");
    } else {
      showSnackbar(`Error al eliminar el cliente: ${response.answer}`, "error");
    }
  };

  // Cargar clientes cuando se monta el componente o cambia `tableData`
  useEffect(() => {
    fetchClientes();
  }, [tableData]);

  return (
    <FormComponentsProvider>
      <Container>
        <Grid container spacing={4}>
          {/* Componente de formulario para agregar clientes */}
          <Grid item xs={12} sm={6}>
            <InputTable
              title="Datos de Cliente"
              rows={[
                [
                  { type: 'text', label: 'Nombre', value: nombre, setValue: setNombre, capitalize: true, borderColor: 'white' }
                ],
                [
                  { type: 'number', label: 'Celular', value: celular, setValue: setCelular, adornment: '', borderColor: 'white' },
                ]
              ]}
              onButtonClick={handleAddClient}
              buttonText="Agregar Cliente"
            />
          </Grid>

          {/* Componente de tabla para mostrar la lista de clientes */}
          <Grid item xs={12} sm={6}>
            <DataTable
              title="Lista de Clientes"
              columns={[
                { field: 'nombre', headerName: 'Nombre' },
                { field: 'celular', headerName: 'Celular' }
              ]}
              rows={clientes}
              filterField="nombre"
              maxHeight="300px"
              selectableRow={true}
              onRowSelect={(row) => console.log('Fila seleccionada:', row)}
              enableDelete={true}
              onDelete={(row) => handleDeleteClient(row.idClient)}
            />
          </Grid>
        </Grid>

        {/* Snackbar para mensajes de éxito o error */}
        <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={() => setSnackbar({ ...snackbar, open: false })}>
          <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity} variant="filled">
            {snackbar.message}
          </Alert>
        </Snackbar>
      </Container>
    </FormComponentsProvider>
  );
};

export default Clientes;
