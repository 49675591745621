import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import './OpcionCard.css';

interface OpcionCardProps {
  titulo: string;
  imagen: string;
  onClick: () => void;
}

const OpcionCard: React.FC<OpcionCardProps> = ({ titulo, imagen, onClick }) => {
  return (
    <Button
      onClick={onClick}
      className="opcion-card-button"
    >
      <Box
        component="img"
        src={imagen}
        alt={titulo}
        className="opcion-card-image"
      />
      <Typography
        variant="body1"
        className="opcion-card-text"
      >
        {titulo}
      </Typography>
    </Button>
  );
};

export default OpcionCard;
