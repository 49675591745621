import React from 'react';
import Header from './Header';
import Footer from './Footer';
import { Box } from '@mui/material';
import MainSection from './MainSection';

const HomePage: React.FC = () => {
  return (
    <Box className="home-page">
      <Header />
      <MainSection />
      {/* <Footer /> */}
    </Box>
  );
};

export default HomePage;


/*
  return (
    <Box className="home-page">
      <Header />
      <Container maxWidth="xl" sx={{ my: 4, px: 2 }}> 
        <ImageGrid imageUrls={imageUrls} />
      </Container>
      <Footer />
    </Box>
  );
};

export default HomePage;



import React from 'react';
import Header from './Header';
import MainBanner from './MainBanner';
import ServicesSection from './ServicesSection';
import ContactSection from './ContactSection';
import AboutUs from './AboutUs';
import useScrollToAnchor from '../../hooks/useScrollToAnchor';
import { Box, Container } from '@mui/material';
import ImageBanner from './ImageBanner';
import Footer from './Footer';

const HomePage: React.FC = () => {
  useScrollToAnchor();

  return (
    <Box className="home-page"> 
      <Header /> 
      <ImageBanner
        imageUrl="images/main/fondo1.png"
        overlayColor="rgba(0, 0, 0, 0)"
      />
      
      <ImageBanner
        imageUrl="images/main/fondo2.png"
        overlayColor="rgba(0, 0, 0, 0)"
      />
      <ImageBanner
        imageUrl="images/main/fondo3.png"
        overlayColor="rgba(0, 0, 0, 0)"
      />
      <ImageBanner
        imageUrl="images/main/fondo4.png"
        overlayColor="rgba(0, 0, 0, 0)"
      />
      <Footer/>
    </Box>
  );
}

export default HomePage;
*/