import React from 'react';
import './Header.css';

const Header: React.FC = () => {
  return (
    <header className="header">
      <div className="logo-container">
        <a href="https://novaasesores.com/" target="_blank" rel="noopener noreferrer" className="logo-link">
          <img src="/images/logo_nova_2_fuego.png" alt="NOVA" className="logo-image" />
        </a>
      </div>
      <div className="bottom-line"></div>
    </header>
  );
}

export default Header;
