// Arqueo.tsx
import React, { useState, useMemo } from 'react';
import { Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Grid, Button, TextField } from '@mui/material';
import { PersonaDatosMezclados, ProductosDatosMezclados } from '../../../interfaces/models';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  control: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '20px 0',
  },
  button: {
    minWidth: '40px',
    minHeight: '40px',
    fontSize: '24px',
  },
  monthYearText: {
    fontSize: '24px',
    fontWeight: 'bold',
    margin: '0 20px',
  },
});

interface Props {
  datosPersonas: PersonaDatosMezclados[];
  datosProductos: ProductosDatosMezclados[];
}

const Arqueo: React.FC<Props> = ({ datosPersonas, datosProductos }) => {
  const [mesSeleccionado, setMesSeleccionado] = useState(new Date().getMonth() + 1);
  const [anioSeleccionado, setAnioSeleccionado] = useState(new Date().getFullYear());
  const nombresMeses = [
    'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
    'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
  ];
  const classes = useStyles();

  const datosFiltradosProductos = useMemo(() => {
    return datosProductos.map(producto => ({
      ...producto,
      ventas: producto.ventas.filter(venta => {
        const ventaDate = new Date(venta.createdAt!);
        return ventaDate.getMonth() + 1 === mesSeleccionado && ventaDate.getFullYear() === anioSeleccionado;
      })
    }));
  }, [datosProductos, mesSeleccionado, anioSeleccionado]);

  const datosFiltradosPersonas = useMemo(() => {
    return datosPersonas.map(persona => ({
      ...persona,
      asistencias: persona.asistencias.filter(asistencia => {
        const asistenciaDate = new Date(asistencia.createdAt!);
        return asistenciaDate.getMonth() + 1 === mesSeleccionado && asistenciaDate.getFullYear() === anioSeleccionado;
      }),
      ventas: persona.ventas.filter(venta => {
        const ventaDate = new Date(venta.createdAt!);
        return ventaDate.getMonth() + 1 === mesSeleccionado && ventaDate.getFullYear() === anioSeleccionado;
      })
    }));
  }, [datosPersonas, mesSeleccionado, anioSeleccionado]);

  const totalVentasProductos = datosFiltradosProductos
  .filter(producto => producto.type === 'producto')
  .reduce((total, producto) => 
    total + producto.ventas.reduce((sum, venta) => 
      sum + (venta.quantity ?? 0) * ((producto.salesPrice ?? 0) - (producto.purchasePrice ?? 0)), 0)
  , 0);

const totalVentasServicios = datosFiltradosProductos
  .filter(producto => producto.type === 'servicio')
  .reduce((total, producto) => 
    total + producto.ventas.reduce((sum, venta) => 
      sum + (venta.quantity ?? 0) * ((producto.salesPrice ?? 0) - (producto.purchasePrice ?? 0)), 0)
  , 0);

  const totalSueldos = datosFiltradosPersonas.reduce((total, persona) => {
    console.log("----- Persona debug -----");
    console.log("Nombre: ", persona.firstName, persona.lastName);
    console.log("DiasTrabajar: ", persona.diasTrabajar);
    console.log("Salario: ", persona.salario);
    console.log("Asistencias: ", persona.asistencias);
  
    const totalDiasTrabajados = persona.asistencias.filter(asistencia => asistencia.pagado === true).length;
    console.log("Total Días Trabajados: ", totalDiasTrabajados);
  
    const diasTrabajar = persona.diasTrabajar ?? 1;
    const salario = persona.salario ?? 0;
  
    const sueldoPersona = (totalDiasTrabajados / diasTrabajar) * salario;
    
    console.log("Cálculo de sueldo -> (totalDiasTrabajados / diasTrabajar) * salario: ", totalDiasTrabajados, diasTrabajar, salario);
    console.log("Sueldo Persona: ", sueldoPersona);
  
    // Verificamos si el sueldoPersona o el total acumulado es NaN, en tal caso lo reemplazamos por 0
    const nuevoTotal = total + (isNaN(sueldoPersona) ? 0 : sueldoPersona);
    console.log("Nuevo Total después de agregar sueldoPersona: ", nuevoTotal);
  
    return isNaN(nuevoTotal) ? 0 : nuevoTotal;
  }, 0);
  
  
  console.log("----- totalSueldos: ", totalSueldos);
    

const totalComisiones = datosFiltradosPersonas.reduce((total, persona) => {
  return total + persona.ventas.reduce((sum, venta) => sum + (venta.valorComisionAPagar ?? 0), 0);
}, 0);

// Asegúrate de que todos los valores usados en el cálculo de utilidadReal son números válidos
const utilidadReal = (totalVentasProductos + totalVentasServicios - totalSueldos - totalComisiones) || 0;

console.log(" ----- utilidad_real: ", utilidadReal)
console.log(" ----- totalVentasProductos: ", totalVentasProductos)
console.log(" ----- totalVentasServicios: ", totalVentasServicios)
console.log(" ----- totalSueldos: ", totalSueldos)
console.log(" ----- totalComisiones: ", totalComisiones)

  const handleNextMonth = () => {
    if (mesSeleccionado === 12) {
      setMesSeleccionado(1);
      setAnioSeleccionado(prev => prev + 1);
    } else {
      setMesSeleccionado(prev => prev + 1);
    }
  };

  const handlePreviousMonth = () => {
    if (mesSeleccionado === 1) {
      setMesSeleccionado(12);
      setAnioSeleccionado(prev => prev - 1);
    } else {
      setMesSeleccionado(prev => prev - 1);
    }
  };

  return (
    <Box sx={{ p: 2 }}>
      <Grid container justifyContent="center" alignItems="center" className={classes.control} spacing={2}>
        <Grid item>
          <Button className={classes.button} onClick={handlePreviousMonth}>&lt;</Button>
        </Grid>
        <Grid item>
          <Typography className={classes.monthYearText}>
            {`${nombresMeses[mesSeleccionado - 1]} ${anioSeleccionado}`}
          </Typography>
        </Grid>
        <Grid item>
          <Button className={classes.button} onClick={handleNextMonth}>&gt;</Button>
        </Grid>
      </Grid>

      <Typography variant="h6" align="center">RESUMEN MENSUAL</Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
          <TableRow>
            <TableCell style={{ fontWeight: 'bold' }}>Descripción</TableCell>
            <TableCell align="right" style={{ fontWeight: 'bold' }}>
              Monto (Bs)
            </TableCell>
          </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>Utilidad Venta de Productos</TableCell>
              <TableCell align="right">{totalVentasProductos.toFixed(2)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Utilidad Servicios Realizados</TableCell>
              <TableCell align="right">{totalVentasServicios.toFixed(2)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Comisiones Pagadas</TableCell>
              <TableCell align="right">{totalComisiones.toFixed(2)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Sueldos Pagados</TableCell>
              <TableCell align="right">{totalSueldos.toFixed(2)}</TableCell>
            </TableRow>
            <TableRow style={{ backgroundColor: '#d4edda' }}> {/* Fondo verde claro */}
              <TableCell style={{ fontWeight: 'bold' }}>Utilidad Real</TableCell> {/* Negrita */}
              <TableCell align="right" style={{ fontWeight: 'bold' }}>{utilidadReal.toFixed(2)}</TableCell> {/* Negrita */}
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default Arqueo;
