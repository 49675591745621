import React, { useState, useEffect } from 'react';
import { TextField, Grid, InputAdornment, Box, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from '@mui/material';
import PhotoIcon from '@mui/icons-material/Photo';

interface AgregarProductoIzquierdaProductoProps {
    datoDetalles: string;
    setDatoDetalles: React.Dispatch<React.SetStateAction<string>>;
    datoMarca: string;
    setDatoMarca: React.Dispatch<React.SetStateAction<string>>;
    datoCostoCompra: number;
    setDatoCostoCompra: React.Dispatch<React.SetStateAction<number>>;
    datoCostoVenta: number;
    setDatoCostoVenta: React.Dispatch<React.SetStateAction<number>>;
    datoCantidad: number;
    setDatoCantidad: React.Dispatch<React.SetStateAction<number>>;
    datoImagen: string;
    setDatoImagen: React.Dispatch<React.SetStateAction<string>>;
    handleTextFieldChange: (
        setFunction: React.Dispatch<React.SetStateAction<string>>,
        transformFunction?: (value: string) => string
    ) => (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleTextFieldBlur: (
        setFunction: React.Dispatch<React.SetStateAction<string>>,
        transformFunction: (value: string) => string
    ) => (event: React.FocusEvent<HTMLInputElement>) => void;
    handleNumberFieldChange: (
        setFunction: React.Dispatch<React.SetStateAction<number>>,
        transformFunction?: (value: string) => number
    ) => (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleNumberFieldBlur: (
        setFunction: React.Dispatch<React.SetStateAction<number>>,
        transformFunction: (value: string) => number
    ) => (event: React.FocusEvent<HTMLInputElement>) => void;
    formatName: (name: string) => string;
    formatNameMinuscula: (name: string) => string;
    formatAmount: (amount: string) => number;
    imagesRutas: string[];
    descripcionImagenes: string[];
    errors: { [key: string]: boolean };
    stockActual: number;
}

const AgregarProductoIzquierdaProducto: React.FC<AgregarProductoIzquierdaProductoProps> = ({
    datoDetalles,
    setDatoDetalles,
    datoMarca,
    setDatoMarca,
    datoCostoCompra,
    setDatoCostoCompra,
    datoCostoVenta,
    setDatoCostoVenta,
    datoCantidad,
    setDatoCantidad,
    datoImagen,
    setDatoImagen,
    handleTextFieldChange,
    handleTextFieldBlur,
    handleNumberFieldChange,
    handleNumberFieldBlur,
    formatName,
    formatNameMinuscula,
    formatAmount,
    imagesRutas,
    descripcionImagenes,
    errors,
    stockActual,
}) => {
    const [open, setOpen] = useState(false);
    const [filtro, setFiltro] = useState('');
    const [imagenesFiltradas, setImagenesFiltradas] = useState<string[]>([]);
    const [imagenesFiltradasNombre, setImagenesFiltradasNombre] = useState<string[]>([]);
    const [localCostoCompra, setLocalCostoCompra] = useState(datoCostoCompra.toString());
    const [localPrecioVenta, setLocalPrecioVenta] = useState(datoCostoVenta.toString()); // Estado local para "Precio Venta"
    
    useEffect(() => {
        const filtradas = imagesRutas.filter((ruta, index) =>
            descripcionImagenes[index].toLowerCase().includes(filtro.toLowerCase())
        );
        const nombresFiltrados = descripcionImagenes.filter((descripcion) =>
            descripcion.toLowerCase().includes(filtro.toLowerCase())
        );
        setImagenesFiltradas(filtradas);
        setImagenesFiltradasNombre(nombresFiltrados);
    }, [filtro, imagesRutas, descripcionImagenes]);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setFiltro(""); 
    };

    const handleListItemClick = (imgRuta: string) => {
        setDatoImagen(imgRuta);
        setOpen(false);
        setFiltro("");
    };

    return (
        <Grid item xs={12} md={6}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label="Descripcion"
                        variant="outlined"
                        value={datoDetalles}
                        onChange={handleTextFieldChange(setDatoDetalles, formatName)}
                        error={errors.datoDetalles}
                        helperText={errors.datoDetalles ? "Este campo es obligatorio" : ""}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        label="Marca"
                        value={datoMarca}
                        onChange={handleTextFieldChange(setDatoMarca, formatName)}
                        error={errors.datoMarca}
                        helperText={errors.datoMarca ? "Este campo es obligatorio" : ""}
                    />
                </Grid>
                <Grid item xs={12}>
    <TextField
        fullWidth
        label="Costo Compra"
        variant="outlined"
        value={localCostoCompra === "0" || localCostoCompra === "0.00" ? "" : localCostoCompra} // Muestra vacío si es 0 o 0.00
        onChange={(e) => setLocalCostoCompra(e.target.value)} // Permite cualquier entrada
        onBlur={() => {
            let formattedValue = parseFloat(localCostoCompra.replace(",", "."));
            if (isNaN(formattedValue)) formattedValue = 0;
            setDatoCostoCompra(formattedValue); // Actualiza al padre como número
            setLocalCostoCompra(formattedValue.toFixed(2)); // Formatea el valor local
        }}
        InputProps={{
            endAdornment: (
                <InputAdornment position="end" style={{ color: 'gray' }}>
                    Bs
                </InputAdornment>
            ),
        }}
        error={errors.datoCostoCompra}
    />
</Grid>


<Grid item xs={12}>
    <TextField
        fullWidth
        label="Precio Venta"
        variant="outlined"
        value={localPrecioVenta === "0" || localPrecioVenta === "0.00" ? "" : localPrecioVenta} // Muestra vacío si es 0 o 0.00
        onChange={(e) => setLocalPrecioVenta(e.target.value)} // Permite cualquier entrada
        onBlur={() => {
            let formattedValue = parseFloat(localPrecioVenta.replace(",", "."));
            if (isNaN(formattedValue)) formattedValue = 0;
            setDatoCostoVenta(formattedValue); // Actualiza al padre como número
            setLocalPrecioVenta(formattedValue.toFixed(2)); // Formatea el valor local
        }}
        InputProps={{
            endAdornment: (
                <InputAdornment position="end" style={{ color: 'gray' }}>
                    Bs
                </InputAdornment>
            ),
        }}
        error={errors.datoCostoVenta}
        helperText={errors.datoCostoVenta ? "Este campo es obligatorio" : ""}
    />
</Grid>
                <Grid item xs={12}>
    <TextField
        fullWidth
        label="Unidades"
        variant="outlined"
        value={datoCantidad.toString()}
        onChange={handleNumberFieldChange(setDatoCantidad, (value) => formatAmount(value))}
        onBlur={handleNumberFieldBlur(setDatoCantidad, (value) => formatAmount(value))}
        
        InputProps={{
            endAdornment: (
                <InputAdornment 
                    position="end" 
                    style={{ 
                        color: stockActual > 0 ? 'red' : 'gray', 
                        fontWeight: stockActual > 0 ? 'bold' : 'normal' 
                    }}
                >
                    Stock {stockActual}
                </InputAdornment>
            ),
        }}
        error={errors.datoCantidad}
        helperText={errors.datoCantidad ? "Este campo es obligatorio" : ""}
    />
</Grid>

                <Grid item xs={12}>
                    <Box>
                        <IconButton onClick={handleClickOpen} color="primary" aria-label="upload picture" component="span">
                            <PhotoIcon />
                            {datoImagen && <img src={datoImagen} alt="Seleccionada" style={{ width: 50, marginLeft: 10 }} />}
                        </IconButton>
                        <Dialog open={open} onClose={handleClose} fullWidth>
  <DialogTitle style={{ fontWeight: 'bold', textAlign: 'center' }}>SELECCIONAR IMAGEN</DialogTitle> 
  <DialogContent>
    {/* Caja para el campo de búsqueda con estilo sticky */}
    <Box style={{ position: 'sticky', top: 0, zIndex: 10, backgroundColor: 'white', paddingTop: '10px', paddingBottom: '10px' }}>
      <TextField
        margin="dense"
        id="search"
        label="Buscar"
        type="text"
        fullWidth
        variant="outlined"
        className="buscar-input"
        onChange={(e) => setFiltro(e.target.value)}
        style={{ marginBottom: '10px' }} 
      />
    </Box>
    {/* Contenedor de las imágenes */}
    <Grid container spacing={2} justifyContent="center">
      {imagenesFiltradas.map((imgRuta, index) => (
        <Grid item xs={6} key={imgRuta}>
          <Box
            onClick={() => handleListItemClick(imgRuta)}
            role="button"
            sx={{ textAlign: 'center', cursor: 'pointer' }}
            style={{ border: '1px solid #ccc', padding: '10px', borderRadius: '8px' }}
          >
            <Typography variant="body2" style={{ fontWeight: 'bold', marginBottom: '10px' }}>
              {imagenesFiltradasNombre[index]}
            </Typography>
            <img
              src={imgRuta}
              alt={imagenesFiltradasNombre[index]}
              style={{ width: '100%', maxWidth: '100px', height: '100px', borderRadius: '4px' }}
            />
          </Box>
        </Grid>
      ))}
    </Grid>
  </DialogContent>
  <DialogActions style={{ justifyContent: 'center' }}>
    <Button onClick={handleClose} color="primary" style={{ fontWeight: 'bold' }}>
      Cancelar
    </Button>
  </DialogActions>
</Dialog>

                    </Box>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default AgregarProductoIzquierdaProducto;
