import React from 'react';
import './ImagePair.css';

interface ImagePairProps {
  image1: string;
  image2: string;
  alt1: string;
  alt2: string;
}

const ImagePair: React.FC<ImagePairProps> = ({ image1, image2, alt1, alt2 }) => {
  return (
    <div className="image-pair-container">
      <img src={image1} alt={alt1} className="image image-top" />
      <img src={image2} alt={alt2} className="image image-bottom" />
    </div>
  );
};

export default ImagePair;
