import React, { useState } from 'react';
import { Box, Button } from '@mui/material';
import { NavLink } from 'react-router-dom';
import './Atencion.css';
import Venta from './Venta/Venta';
import ControlOperario from './controlOperario/ControlOperario';
import OpcionesGrid from '../../../general/OpcionesGrid';

const Atencion: React.FC = () => {
  const [activeSection, setActiveSection] = useState<string | null>(null); // Estado para controlar la vista seleccionada
  const [enVistaSeleccion, setEnVistaSeleccion] = useState(true); // Estado para la vista inicial

  const handleSeleccionarSeccion = (seccion: string) => {
    setActiveSection(seccion);
    setEnVistaSeleccion(false);
  };

  const handleVolverASeleccion = () => {
    setActiveSection(null);
    setEnVistaSeleccion(true);
  };

  const renderSection = () => {
    switch (activeSection) {
      case 'controlOperario':
        return <ControlOperario />;
      case 'venta':
        return <Venta />;
      default:
        return <></>;
    }
  };

  const opciones = [
    { titulo: 'Personal', imagen: '/images/opciones/33.png', onClick: () => handleSeleccionarSeccion('controlOperario') },
    { titulo: 'Venta', imagen: '/images/opciones/34.png', onClick: () => handleSeleccionarSeccion('venta') },
  ];

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {enVistaSeleccion ? (
        // Vista inicial usando OpcionesGrid con opciones de selección
        <Box sx={{ width: '100%', maxWidth: '80vw', flexGrow: 1 }}>
          <OpcionesGrid opciones={opciones} />

          {/* Botones de "Atrás" y "Home" en la vista inicial */}
          <Box
            className="bottom-row"
            sx={{
              display: 'flex',
              justifyContent: 'center',
              gap: 2,
              padding: 2,
              marginTop: 4,
            }}
          >
            <Button component={NavLink} to="/salones" className="bottom-icon-button">
              <img src="/images/iconos/flecha_atras_negro.png" alt="Back" className="bottom-icon" />
            </Button>
            <Button component={NavLink} to="/" className="bottom-icon-button">
              <img src="/images/iconos/home_back.png" alt="Home" className="bottom-icon" />
            </Button>
          </Box>
        </Box>
      ) : (
        // Vista de la sección seleccionada con botón de "Atrás"
        <Box sx={{ padding: '0 16px', flexGrow: 1, width: '100%', maxWidth: '80vw' }}>
          {renderSection()}
          <Box
            className="bottom-row"
            sx={{
              display: 'flex',
              justifyContent: 'center',
              gap: 2,
              padding: 2,
              marginTop: 4,
            }}
          >
            <Button onClick={handleVolverASeleccion} className="bottom-icon-button">
              <img src="/images/iconos/flecha_atras_negro.png" alt="Back" className="bottom-icon" />
            </Button>
            <Button component={NavLink} to="/" className="bottom-icon-button">
              <img src="/images/iconos/home_back.png" alt="Home" className="bottom-icon" />
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default Atencion;
