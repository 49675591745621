import axios from 'axios';
import { ClientAttributesInterface } from '../interfaces/models';

const api = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_ENDPOINT,
});

// Crear Cliente
export const clientCreate = async (data: ClientAttributesInterface) => {
    try {
        const response = await api.post('/clientsCreate', data);
        console.log("response ", response.data)
        return response.data;
    } catch (error) {
        console.log("error: ", error);
    }
};

// Leer Todos los Clientes
export const clientReadAll = async (idSalon = 0) => {
    try {
        const response = await api.get(`/clientsReadAll?idSalon=${idSalon}`);
        return response.data.data;
    } catch (error) {
        console.log("error: ", error);
    }
};

// Leer Cliente Único
export const clientReadUnique = async (id: number) => {
    try {
        const response = await api.get(`/clientsReadUnique/${id}`);
        return response.data;
    } catch (error) {
        console.log("error: ", error);
    }
};

// Actualizar Cliente
export const clientUpdate = async (data: ClientAttributesInterface) => {
    try {
        const response = await api.put(`/clientsUpdate/${data.idClient}`, data);
        return response.data;
    } catch (error) {
        console.log("error: ", error);
    }
};

// Eliminar Cliente
export const clientDelete = async (id: number) => {
    try {
        const response = await api.delete(`/clientsDelete/${id}`);
        return response.data;
    } catch (error) {
        console.log("error: ", error);
    }
};
