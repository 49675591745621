import React, { useState, useEffect, useContext } from 'react';
import {
  Box,
  Button,
  TextField,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Alert,
  Grid,
  Dialog, DialogTitle, DialogContent, DialogActions,
  FormControlLabel,
  Checkbox
} from '@mui/material';
import styled from 'styled-components';
import { AsistenciaAttributes, UserAttributes } from '../../../../interfaces/models';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import InventoryContext from '../../../../contexts/InventoryContext';

const handleNumberFieldChange = (
  setFunction: React.Dispatch<React.SetStateAction<number>>,
  transformFunction?: (value: string) => number
) => (event: React.ChangeEvent<HTMLInputElement>) => {
  const newValue = event.target.value;
  const numericValue = parseFloat(newValue);
  console.log("Campo numérico cambiado:", numericValue);
  setFunction(transformFunction ? transformFunction(newValue) : numericValue);
};
const handleNumberFieldBlur = (
  setFunction: React.Dispatch<React.SetStateAction<number>>,
  transformFunction: (value: string) => number
) => (event: React.FocusEvent<HTMLInputElement>) => {
  const newValue = event.target.value;
  console.log("Campo numérico desenfocado:", newValue);
  setFunction(transformFunction(newValue));
};


const formatAmount = (amount: string): number => {
  // Reemplazar todas las comas por puntos antes de la conversión y eliminar puntos de miles si hay
  const convertedAmount = amount.replace(/,/g, '');

  // Comprobar si el valor convertido es un número válido, de lo contrario, devolver 0
  const numericValue = parseFloat(convertedAmount);
  if (isNaN(numericValue)) {
    return 0;
  }
  const answer = parseFloat(numericValue.toFixed(2));
  console.log(answer)
  // Devolver el número redondeado a 2 decimales como flotante
  return answer;
};

const useStyles = makeStyles((theme: Theme) => ({
  textField: {
    '& .MuiInputLabel-outlined': {
      transform: 'translate(14px, 10px) scale(1)',
      backgroundColor: 'white',
      padding: '0 4px',
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(14px, -6px) scale(0.75)',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: theme.palette.primary.main,
      },
      '&:hover fieldset': {
        borderColor: theme.palette.primary.main,
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.primary.main,
      },
    },
  },  
  formControlLabel: {
    color: theme.palette.text.primary,
  },
  inputAdelanto: {
    marginTop: theme.spacing(2),
  },
}));

interface ScrollableBoxProps {
  children: React.ReactNode;
}

const ScrollableBox: React.FC<ScrollableBoxProps> = ({ children }) => (
  <div style={{ maxHeight: '600px', overflowY: 'auto' }}>
    {children}
  </div>
);

interface SeccionDerechaProps {
  usuarioSeleccionado: string;
  asistencias: AsistenciaAttributes[];
  usuarios: UserAttributes[];
  //crearAsistencia2: (observaciones: string) => Promise<void>;
  //marcarSalida2: (idAsistencia: number, notaTemporal: string) => Promise<void>;
  mensaje2: string;
  operacionExitosa2: null | boolean;
  setMensaje: (message: string)=>void;
  setOperacionExitosa: (data: null | boolean)=>void;
}

export const SeccionDerecha: React.FC<SeccionDerechaProps> = ({
  usuarioSeleccionado,
  asistencias,
  usuarios,
  //crearAsistencia2,
  //marcarSalida2,
  mensaje2,
  operacionExitosa2,
  setOperacionExitosa,
  setMensaje,
}) => {
  const context = useContext(InventoryContext);

  if (!context) {
    throw new Error('UserList must be used within an InventoryProvider');
  }
  const { tableData, deleteItem, addItem, updateItem } = context;


  const classes = useStyles();
  const [observaciones, setObservaciones] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [detalleEditando, setDetalleEditando] = useState<AsistenciaAttributes | null>(null);
  const [esAdelanto, setEsAdelanto] = useState(false);
  const [valorAdelanto, setValorAdelanto] = useState(0);

  const handleObservacionesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setObservaciones(event.target.value);
  };


  const usuarioActual = usuarios.find(usuario => usuario.idUser!.toString() === usuarioSeleccionado);

  const StyledTableCell = styled(TableCell)`
    text-transform: uppercase;
    font-weight: bold;
  `;

  const encontrarNombreUsuario = (idUsuario: number | undefined) => {
    const usuario = tableData.usuarios.find(user => user.idUser === idUsuario);
    return usuario ? `${usuario.firstName} ${usuario.lastName}` : "Usuario desconocido";
  };

  const asistenciasOrdenadas = [...tableData.asistencias]
  .filter(asistencia => !asistencia.salida)  // Filtra las asistencias que tienen 'salida' en false
  .sort((a, b) => {
    return new Date(b.createdAt!).getTime() - new Date(a.createdAt!).getTime();
  });

  const usuarioYaTrabajando = tableData.asistencias.some(asistencia => asistencia.idUser === parseInt(usuarioSeleccionado) && !asistencia.salida);

  const handleOpenDialog = (asistencia: AsistenciaAttributes) => {
    setDetalleEditando(asistencia);
    setObservaciones(asistencia.notas || "");
    setEsAdelanto(false); // Siempre desactivado al abrir
    setValorAdelanto(0);  // Siempre a 0 al abrir
    setValorAdelantoTemp(""); // Iniciar vacío
    setOpenDialog(true);
  };
  
  
  const crearAsistencia = async (observaciones: string) => {
    if (!usuarioSeleccionado) {
      alert("Por favor, seleccione un usuario.");
      return;
    }

    try {
      const payload:AsistenciaAttributes = {
        idUser: Number(usuarioSeleccionado),
        notas: observaciones,
      };
      const response = await addItem('asistencias', payload)//asistenciaCreate(payload);
      /*if(response == null) { // AGREGAR ERRORES
          console.log("error 500"); 
          setMensaje('Error al crear la asistencia');
          setOperacionExitosa(false);
          return;
      }*/
      //await api.post('/asistenciaCreate', payload);
      setMensaje('Asistencia registrada con éxito.');
      setOperacionExitosa(true);
      // Refetch asistencias data to update UI
      //const asistenciaResponse = await asistenciaReadAllActive();
      //setAsistencias(asistenciaResponse);
    } catch (error) {
      console.error("Error al crear la asistencia:", error);
    }
  };

  const [valorAdelantoTemp, setValorAdelantoTemp] = useState<string>("");

  return (
    <Paper className="paper">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography
            variant="h6"
            gutterBottom
            style={
              usuarioActual
                ? {
                    color: 'blue',
                    fontWeight: 'bold',
                    fontSize: '1.5rem',
                    textDecoration: 'underline',
                  }
                : {}
            }
          >
            {usuarioActual ? `${usuarioActual.firstName} ${usuarioActual.lastName}` : "Usuario no seleccionado"}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Observaciones"
            variant="outlined"
            value={observaciones}
            onChange={handleObservacionesChange}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => { crearAsistencia(observaciones); 
              //crearAsistencia2(observaciones); 
              setObservaciones(""); }}
            disabled={usuarioYaTrabajando}
          >
            {usuarioYaTrabajando ? "EL USUARIO YA ESTÁ TRABAJANDO" : "Registrar Asistencia"}
          </Button>
        </Grid>
      </Grid>

      {operacionExitosa2 !== null && (
        <Alert severity={operacionExitosa2 ? "success" : "error"} sx={{ mt: 2 }}>
          {mensaje2}
        </Alert>
      )}

      <ScrollableBox>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ textTransform: 'uppercase', fontWeight: 'bold' }}>Nombre</TableCell>
                <TableCell sx={{ textTransform: 'uppercase', fontWeight: 'bold' }}>Hora de Entrada</TableCell>
                <TableCell sx={{ textTransform: 'uppercase', fontWeight: 'bold' }}>Observaciones</TableCell>
                <TableCell sx={{ textTransform: 'uppercase', fontWeight: 'bold' }}>Acciones</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {asistenciasOrdenadas.map((asistencia) => (
                <TableRow key={asistencia.idAsistencia}>
                  <TableCell>{encontrarNombreUsuario(asistencia.idUser)}</TableCell>
                  <TableCell>{new Date(asistencia.createdAt!).toLocaleTimeString()}</TableCell>
                  <TableCell>{asistencia.notas}</TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => handleOpenDialog(asistencia)} // Usa la nueva función
                      disabled={asistencia.salida}
                    >
                      Marcar Salida
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </ScrollableBox>

      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
  <DialogTitle style={{ fontWeight: 'bold', textAlign: 'center', textTransform: 'uppercase' }}>
    Editar Asistencia
  </DialogTitle>
  <DialogContent>
    <TextField
      autoFocus
      margin="dense"
      id="nota"
      label="Nota"
      type="text"
      fullWidth
      variant="outlined"
      value={observaciones}
      onChange={(e) => setObservaciones(e.target.value)}
      className={classes.textField}
    />
    <FormControlLabel
      control={
        <Checkbox
          checked={esAdelanto}
          onChange={(e) => {
            setEsAdelanto(e.target.checked);
            if (!e.target.checked) {
              setValorAdelanto(0); // Siempre restablecer a 0 si se desactiva
              setValorAdelantoTemp(""); // Vaciar el valor temporal también
            }
          }}
          name="esAdelanto"
          color="primary"
        />
      }
      label={<Typography style={{ fontWeight: 'bold' }}>Adelanto</Typography>}
      className={classes.formControlLabel}
    />

    <TextField
      margin="dense"
      id="valorAdelanto"
      label="Valor"
      fullWidth
      variant="outlined"
      value={esAdelanto ? valorAdelantoTemp : ""} // Mostrar vacío si el checkbox no está activado
      onChange={(e) => setValorAdelantoTemp(e.target.value)}
      onBlur={(e) => {
        const value = e.target.value;
        const numericValue = parseFloat(value.replace(/[^\d.]/g, ''));

        if (!isNaN(numericValue)) {
          setValorAdelanto(numericValue);
          setValorAdelantoTemp(numericValue.toString() + " Bs");
        } else {
          alert("Por favor, ingrese un valor numérico válido.");
          setValorAdelanto(0);
          setValorAdelantoTemp("");
        }
      }}
      disabled={!esAdelanto} // Solo activado si el checkbox está seleccionado
      className={classes.textField}
    />
  </DialogContent>
  <DialogActions>
    <Button
      onClick={() => {
        setOpenDialog(false);
        setObservaciones("");
        setEsAdelanto(false); // Restablecer a false
        setValorAdelanto(0);  // Restablecer a 0
        setValorAdelantoTemp(""); // Limpiar valor temporal
      }}
    >
      Cancelar
    </Button>
    <Button
      onClick={() => {
        if (detalleEditando) {
          updateItem('asistencias', 
            detalleEditando.idAsistencia!,
            {
              idAsistencia: detalleEditando.idAsistencia!,
              notas: observaciones,
              adelanto: esAdelanto,
              valorAdelanto: esAdelanto ? valorAdelanto : 0,
              idUser: detalleEditando.idUser,
            });
          setOpenDialog(false);
          setObservaciones("");
          setEsAdelanto(false);
          setValorAdelanto(0);
          setValorAdelantoTemp(""); // Limpiar valor temporal
        }
      }}
    >
      Aceptar
    </Button>
  </DialogActions>
</Dialog>

    </Paper>
  );
};
