import React from 'react';
import { Box } from '@mui/material';
import OpcionCard from './OpcionCard';

interface Opcion {
  titulo: string;
  imagen: string;
  onClick: () => void;
}

interface OpcionesGridProps {
  opciones: Opcion[];
}

const OpcionesGrid: React.FC<OpcionesGridProps> = ({ opciones }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexWrap: 'wrap',
        gap: 3,                    // Espacio entre las tarjetas
        padding: 2,                // Espacio interno del contenedor
        height: '100vh',           // Ocupa toda la altura de la pantalla
        boxSizing: 'border-box',   // Asegura que el padding esté incluido en el tamaño total
      }}
    >
      {opciones.map((opcion, index) => (
        <OpcionCard
          key={index}
          titulo={opcion.titulo}
          imagen={opcion.imagen}
          onClick={opcion.onClick}
        />
      ))}
    </Box>
  );
};

export default OpcionesGrid;
