import React, { useState, ReactNode } from 'react';
import {
  TextField,
  Grid,
  List,
  ListItem,
  Typography,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ServiceProductAttributesInterface } from '../../../../interfaces/models';
import './Venta.css';

const ScrollableBox: React.FC<ScrollableBoxProps> = ({ children }) => (
  <div style={{ maxHeight: '600px', overflowY: 'auto', width: '100%' }}>
    {children}
  </div>
);

const RenderSP: React.FC<{data: ServiceProductAttributesInterface, onAddToTicket: () => void}> = ({data, onAddToTicket}) => (
  <Card sx={{ width: '100%' }}>
    <CardActionArea onClick={onAddToTicket}>
      <CardContent>
        <Typography 
          gutterBottom 
          variant="h6" 
          component="div" 
          align="center" 
          style={{ fontWeight: 'bold' }}
        >
          {data.detalles}
        </Typography>
      </CardContent>
      <CardMedia
        component="img"
        image={data.image || `/logo.png`}
        alt={data.detalles}
        style={{ objectFit: 'contain', height: 140 }} // Ajusta según tus necesidades
      />
      <CardContent>
        <Grid container justifyContent="space-between" alignItems="center">
          {data.type === "producto" && (
            <Typography variant="body2" color="text.secondary">
              Stock: {data.quantity}
            </Typography>
          )}
          <Typography variant="body1">
            Precio: Bs. {data.salesPrice!.toFixed(2)}
          </Typography>
        </Grid>
      </CardContent>
    </CardActionArea>
  </Card>
)

interface ScrollableBoxProps {
  children: ReactNode;
}

const useStyles = makeStyles({
  input: {
    '& input': {
      color: 'black',
    },
  },
});

interface VentaDerechaProps {
  addToTicket: (productData: ServiceProductAttributesInterface) => void;
  data: ServiceProductAttributesInterface[];
}

export const VentaDerecha: React.FC<VentaDerechaProps> = ({
  addToTicket,
  data,
}) => {
  const [searchTermProductos, setSearchTermProductos] = useState('');
  const [searchTermServicios, setSearchTermServicios] = useState('');
  const classes = useStyles();

  const handleSearchProductosChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTermProductos(event.target.value.toLowerCase());
  };

  const handleSearchServiciosChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTermServicios(event.target.value.toLowerCase());
  };

  const filteredProductosData = data.filter((producto) => {
    return producto.type === 'producto' && producto.detalles?.toLowerCase().includes(searchTermProductos);
  });

  const filteredServiciosData = data.filter((servicio) => {
    return servicio.type === 'servicio' && servicio.detalles?.toLowerCase().includes(searchTermServicios);
  });

  return (
    <Grid item xs={12} md={12} sx={{ width: '100%' }}>
      <Grid container spacing={2} sx={{ width: '100%' }}>

        {/* Productos */}
        <Grid item xs={12} md={6} sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
          <TextField
            label="Buscar Productos"
            variant="outlined"
            fullWidth
            onChange={handleSearchProductosChange}
            className={classes.input}
          />
          <ScrollableBox>
            <List sx={{ maxHeight: '100%', width: '100%', overflowY: 'auto' }}>
              {filteredProductosData.map((producto, index) => (
                <ListItem key={index} sx={{ width: '100%' }}>
                  <RenderSP data={producto} onAddToTicket={() => addToTicket(producto)} />
                </ListItem>
              ))}
            </List>
          </ScrollableBox>
        </Grid>

        {/* Servicios */}
        <Grid item xs={12} md={6} sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
          <TextField
            label="Buscar Servicios"
            variant="outlined"
            fullWidth
            onChange={handleSearchServiciosChange}
            className={classes.input}
          />
          <ScrollableBox>
            <List sx={{ maxHeight: '100%', width: '100%', overflowY: 'auto' }}>
              {filteredServiciosData.map((servicio, index) => (
                <ListItem key={index} sx={{ width: '100%' }}>
                  <RenderSP data={servicio} onAddToTicket={() => addToTicket(servicio)} />
                </ListItem>
              ))}
            </List>
          </ScrollableBox>
        </Grid>

      </Grid>
    </Grid>
  );
};
