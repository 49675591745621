import React, { useContext } from 'react';
import { Box, Container, Typography, Button } from '@mui/material';
import { NavLink, Outlet } from 'react-router-dom';  // Importa Outlet
import InventoryContext from '../contexts/InventoryContext';
import './MainLayoutAdmin.css';

interface MainLayoutAdminProps {
  onLogout: () => void;
}

const MainLayoutAdmin: React.FC<MainLayoutAdminProps> = ({ onLogout }) => {
  const context = useContext(InventoryContext);
  if (!context) {
    throw new Error('MainLayoutAdmin must be used within an InventoryProvider');
  }

  return (
    <Container maxWidth={false} disableGutters className="main-layout-container">
      {/* Top Row */}
      <Box className="top-row">
      <Typography variant="h4" className="header-text">   </Typography>
      </Box>

      {/* Middle Row */}
      <Box className="middle-row">
        <Button component={NavLink} to="/salones/reportes" className="icon-button">
          <img src="/images/iconos/reportes_5.png" alt="Reportes" className="icon-image" />
          <Typography className="icon-text">Reportes</Typography>
        </Button>
        <Button component={NavLink} to="/salones/administracion" className="icon-button">
          <img src="/images/iconos/administracion.png" alt="Administración" className="icon-image" />
          <Typography className="icon-text">Administración</Typography>
        </Button>
        <Button component={NavLink} to="/salones/atencion" className="icon-button">
          <img src="/images/iconos/caja.png" alt="Caja" className="icon-image" />
          <Typography className="icon-text">Caja</Typography>
        </Button>
        <Button component={NavLink} to="/salones/reservas" className="icon-button">
          <img src="/images/iconos/reservas.png" alt="Reservas" className="icon-image" />
          <Typography className="icon-text">Reservas</Typography>
        </Button>
      </Box>

      {/* Bottom Row */}
      <Box className="bottom-row">
        <Button component={NavLink} to="/" className="bottom-icon-button">
          <img src="/images/iconos/flecha_atras_blanco.png" alt="Back" className="bottom-icon" />
        </Button>
        <Button component={NavLink} to="/" className="bottom-icon-button">
          <img src="/images/iconos/home.png" alt="Home" className="bottom-icon" />
        </Button>
      </Box>

      {/* Aquí se renderizarán las subrutas de /admin */}
      <Outlet />  {/* Este es el lugar donde se renderizarán las subrutas */}
    </Container>
  );
};

export default MainLayoutAdmin;
