import React from 'react';
import { useNavigate } from 'react-router-dom';  // Importa useNavigate
import './MainSection.css';

const MainSection: React.FC = () => {
  const navigate = useNavigate();  // Inicializa el hook useNavigate

  const handleAccessClick = () => {
    navigate('/login');  // Redirige a la ruta '/login' cuando se hace clic
  }

  return (
    <section className="main-section">
      <div className="overlay"></div>
      <div className="content">
        <p>Una experiencia de clase mundial en plataformas de gestión</p>
        <button className="access-button" onClick={handleAccessClick}>ACCESO</button> {/* Añade onClick */}
      </div>
      <img src="/images/main/home.png" alt="Home Icon" className="home-icon" />
    </section>
  );
}

export default MainSection;
