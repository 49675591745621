import React, { useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Paper, Typography } from '@mui/material';

interface DataTableProps {
  title?: string;
  columns: Array<{ field: string; headerName: string }>;
  rows: Array<{ [key: string]: any }>;
  filterField: string;
  filterBackgroundColor?: string;
  headerColor?: string;
  maxHeight?: string;

  selectableRow?: boolean; // Habilita la selección de filas
  onRowSelect?: (row: { [key: string]: any }) => void; // Función para ejecutar al seleccionar fila
  enableDelete?: boolean; // Muestra botón de eliminación
  onDelete?: (row: { [key: string]: any }) => void; // Función para ejecutar al eliminar
  enableEdit?: boolean; // Muestra botón de edición
  onEdit?: (row: { [key: string]: any }) => void; // Función para ejecutar al editar
  extraButton?: boolean; // Muestra botón extra
  extraButtonIcon?: JSX.Element; // Ícono para el botón extra
  onExtraButtonClick?: (row: { [key: string]: any }) => void; // Función para ejecutar al hacer clic en el botón extra
}

const DataTable: React.FC<DataTableProps> = ({
  title,
  columns,
  rows,
  filterField,
  filterBackgroundColor = 'white',
  headerColor = 'black',
  maxHeight = '400px',
  selectableRow = false,
  onRowSelect,
  enableDelete = false,
  onDelete,
  enableEdit = false,
  onEdit,
  extraButton = false,
  extraButtonIcon,
  onExtraButtonClick
}) => {
  const [filterText, setFilterText] = useState('');
  const [selectedRow, setSelectedRow] = useState<number | null>(null); // Track selected row index

  const filteredRows = rows.filter(row =>
    row[filterField].toString().toLowerCase().includes(filterText.toLowerCase())
  );

  const handleRowClick = (rowIndex: number, row: { [key: string]: any }) => {
    if (selectableRow) {
      setSelectedRow(rowIndex);
      if (onRowSelect) {
        onRowSelect(row);
      }
    }
  };

  return (
    <Paper style={{ padding: '20px', margin: '20px', overflow: 'hidden' }}>
      {title && (
        <Typography variant="h5" align="center" gutterBottom style={{ textTransform: 'uppercase', fontWeight: 'bold' }}>
          {title}
        </Typography>
      )}
      <TextField
        label={`Filtrar por ${filterField}`}
        variant="outlined"
        fullWidth
        value={filterText}
        onChange={(e) => setFilterText(e.target.value)}
        style={{ marginBottom: '16px', backgroundColor: filterBackgroundColor }}
      />
      <TableContainer style={{ maxHeight }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.field}
                  style={{
                    backgroundColor: 'white', // Color de fondo blanco para evitar la barra negra
                    color: headerColor, // Color del texto
                    fontWeight: 'bold',
                  }}
                >
                  {column.headerName.toUpperCase()}
                </TableCell>
              ))}
              {(enableDelete || enableEdit || extraButton) && <TableCell>Acciones</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredRows.map((row, rowIndex) => (
              <TableRow
                key={rowIndex}
                onClick={() => handleRowClick(rowIndex, row)}
                style={{
                  backgroundColor: selectedRow === rowIndex ? '#f0f0f0' : 'transparent', // Resalta fila seleccionada
                  cursor: selectableRow ? 'pointer' : 'default',
                }}
              >
                {columns.map((column) => (
                  <TableCell key={column.field}>
                    {row[column.field]}
                  </TableCell>
                ))}
                {(enableDelete || enableEdit || extraButton) && (
                  <TableCell>
                    {enableDelete && onDelete && (
                      <button onClick={(e) => { e.stopPropagation(); onDelete(row); }} style={{ marginRight: '8px' }}>
                        🗑️
                      </button>
                    )}
                    {enableEdit && onEdit && (
                      <button onClick={(e) => { e.stopPropagation(); onEdit(row); }} style={{ marginRight: '8px' }}>
                        ✏️
                      </button>
                    )}
                    {extraButton && onExtraButtonClick && extraButtonIcon && (
                      <button onClick={(e) => { e.stopPropagation(); onExtraButtonClick(row); }}>
                        {extraButtonIcon}
                      </button>
                    )}
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default DataTable;
