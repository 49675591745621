import axios from 'axios';
import { ReservationAttributesInterface } from '../interfaces/models';

const api = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_ENDPOINT,
});

// Crear Reserva
export const reservationCreate = async (data: ReservationAttributesInterface) => {
    try {
        const response = await api.post('/reservationsCreate', data);
        return response.data;
    } catch (error) {
        console.log("error: ", error);
    }
};

// Leer Todas las Reservas
export const reservationReadAll = async (idSalon = 0) => {
    try {
        const response = await api.get(`/reservationsReadAll?idSalon=${idSalon}`);
        return response.data.data;
    } catch (error) {
        console.log("error: ", error);
    }
};

// Leer Reserva Única
export const reservationReadUnique = async (id: number) => {
    try {
        const response = await api.get(`/reservationsReadUnique/${id}`);
        return response.data;
    } catch (error) {
        console.log("error: ", error);
    }
};

// Actualizar Reserva
export const reservationUpdate = async (data: ReservationAttributesInterface) => {
    try {
        const response = await api.put(`/reservationsUpdate/${data.idReserva}`, data);
        return response.data;
    } catch (error) {
        console.log("error: ", error);
    }
};

// Eliminar Reserva
export const reservationDelete = async (id: number) => {
    try {
        const response = await api.delete(`/reservationsDelete/${id}`);
        return response.data;
    } catch (error) {
        console.log("error: ", error);
    }
};
