import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import HomePage from './pages/inicio/HomePage';
import LoginPage from './pages/login/LoginPage';
import AdminPage from './pages/admin/AdminPage';
import Reportes from './pages/admin/Reportes/Reportes';
import Atencion from './pages/admin/Atencion/Atencion';
import Administracion from './pages/admin/Administracion/Administracion';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import MainLayoutAdmin from './general/MainLayoutAdmin';
import { SeccionProvider } from './hooks/SeccionContext';
import { InventoryProvider } from './contexts/InventoryContext';
import RegisterPage from './pages/login/RegisterPage';
import { getDetailsFromToken } from './pages/login/authUtils';
import PaginaReservas from './pages/admin/Clientes/PaginaReservas';

const theme = createTheme({
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          border: 'none',
        },
      },
    },
    MuiPaper: {
      defaultProps: {
        elevation: 0
      },
      styleOverrides: {
        root: {
          boxShadow: 'none'
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: '0.8rem',
          margin: '8px',
          padding: '6px 12px',
        },
      },
    },
  },
  typography: {
    body1: {
      '@media (max-width:600px)': {
        padding: '0 8px',
      },
    },
  },
});

function App() {
  const [salonId, setSalonId] = useState<number | null>(null);
  const [rol, setRol] = useState<number | null>(null);
  const [idUsuario, setIdUsuario] = useState<number | null>(null);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);

  const handleLogin = (token: string) => {
    const { idSalon, rol, idUsuario } = getDetailsFromToken(token);

    setSalonId(idSalon);
    setRol(rol);
    setIdUsuario(idUsuario);
    localStorage.setItem('token', token);
    setIsAuthenticated(true);
    setLoading(false);
  };

  const handleLogout = () => {
    const userConfirmed = window.confirm("¿Estás seguro de que deseas cerrar sesión?");
    
    if (userConfirmed) {
      localStorage.removeItem('token');
      setSalonId(null);
      setRol(null);
      setIdUsuario(null);
      setIsAuthenticated(false);
    }
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      const { idSalon, rol, idUsuario } = getDetailsFromToken(token);

      setSalonId(idSalon);
      setRol(rol);
      setIsAuthenticated(true);
    }
    setLoading(false);
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <SeccionProvider>
          {/* El InventoryProvider se coloca fuera de Routes */}
          <InventoryProvider salonId={salonId} rol={rol} idUsuario={idUsuario}>
            <Routes>
              {/* Rutas públicas */}
              <Route path="/" element={<HomePage />} />
              <Route path="/login" element={<LoginPage onLogin={handleLogin} />} />
              <Route path="/register" element={<RegisterPage />} />

              {/* Rutas protegidas */}
              {isAuthenticated ? (
                <>
                  {/* Rutas que deben ir con el layout de navegación */}
                  <Route path="/salones" element={<MainLayoutAdmin onLogout={handleLogout} />}>
                    {/* Solo renderizar los botones de navegación, pero no los contenidos */}
                  </Route>

                  {/* Rutas que no deben estar dentro del layout de MainLayoutAdmin */}
                  <Route path="/salones/reportes" element={<Reportes />} />
                  <Route path="/salones/administracion" element={<Administracion />} />
                  <Route path="/salones/atencion" element={<Atencion />} />
                  <Route path="/salones/reservas" element={<PaginaReservas />} />
                </>
              ) : (
                // Si no está autenticado, redirigir al login
                <Route path="*" element={<Navigate to="/login" />} />
              )}
            </Routes>
          </InventoryProvider>
        </SeccionProvider>
      </Router>
    </ThemeProvider>
  );
}

export default App;


/*
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import HomePage from './pages/inicio/HomePage';
import LoginPage from './pages/login/LoginPage';
import AdminPage from './pages/admin/AdminPage';
import Reportes from './pages/admin/Reportes/Reportes';
import Atencion from './pages/admin/Atencion/Atencion';
import Administracion from './pages/admin/Administracion/Administracion';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import MainLayoutAdmin from './general/MainLayoutAdmin';
import { SeccionProvider } from './hooks/SeccionContext';
import { InventoryProvider } from './contexts/InventoryContext';
import RegisterPage from './pages/login/RegisterPage';
import { getDetailsFromToken } from './pages/login/authUtils';
import Clientes from './pages/admin/Clientes/Clientes';
import PaginaReservas from './pages/admin/Clientes/PaginaReservas';

const theme = createTheme({
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          border: 'none',
        },
      },
    },
    MuiPaper: {
      defaultProps: {
        elevation: 0 
      },
      styleOverrides: {
        root: {
          boxShadow: 'none' 
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {  
          fontSize: '0.8rem',
          margin: '8px',
          padding: '6px 12px',
        },
      },
    },
  },
  typography: {
    body1: {
      '@media (max-width:600px)': {
        padding: '0 8px',
      },
    },
  },
});

function App() {
  const [salonId, setSalonId] = useState<number | null>(null);
  const [rol, setRol] = useState<number | null>(null);
  const [idUsuario, setIdUsuario] = useState<number | null>(null);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);

  const handleLogin = (token: string) => {
    const { idSalon, rol, idUsuario } = getDetailsFromToken(token);

    setSalonId(idSalon);
    setRol(rol);
    setIdUsuario(idUsuario);
    localStorage.setItem('token', token);
    setIsAuthenticated(true);
    setLoading(false);
  };

  const handleLogout = () => {
    const userConfirmed = window.confirm("¿Estás seguro de que deseas cerrar sesión?");
    
    if (userConfirmed) {
      localStorage.removeItem('token');
      setSalonId(null);
      setRol(null);
      setIdUsuario(null);
      setIsAuthenticated(false);
    }
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      const { idSalon, rol, idUsuario } = getDetailsFromToken(token);

      setSalonId(idSalon);
      setRol(rol);
      setIsAuthenticated(true);
    }
    setLoading(false);
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <SeccionProvider>
          {isAuthenticated ? (
            <InventoryProvider salonId={salonId} rol={rol} idUsuario={idUsuario}>
              <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/login" element={<LoginPage onLogin={handleLogin} />} />
                <Route path="/register" element={<RegisterPage />} />
              */
                {/* Rutas para rol 1 */}
                /*
                {rol === 1 && (
                  <>
                    <Route path="/administracion" element={<MainLayoutAdmin onLogout={handleLogout}><AdminPage /></MainLayoutAdmin>} />
                    <Route path="/reportes" element={<MainLayoutAdmin onLogout={handleLogout}><Reportes /></MainLayoutAdmin>} />
                    <Route path="/administracionTotal" element={<MainLayoutAdmin onLogout={handleLogout}><Administracion /></MainLayoutAdmin>} />
                    <Route path="/atencion" element={<MainLayoutAdmin onLogout={handleLogout}><Atencion /></MainLayoutAdmin>} />
                    <Route path="/reservas" element={<MainLayoutAdmin onLogout={handleLogout}><PaginaReservas /></MainLayoutAdmin>} />
                  </>
                )}
                */
                {/* Rutas para rol 2 */}
                /*
                {rol === 2 && (
                  <>
                    <Route path="/atencion" element={<MainLayoutAdmin onLogout={handleLogout}><Atencion /></MainLayoutAdmin>} />
                  </>
                )}

                <Route path="*" element={<Navigate to={rol === 2 ? "/atencion" : "/"} />} />
              </Routes>
            </InventoryProvider>
          ) : (
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/login" element={<LoginPage onLogin={handleLogin} />} />
              <Route path="/register" element={<RegisterPage />} />
              <Route path="*" element={<Navigate to="/login" />} />
            </Routes>
          )}
        </SeccionProvider>
      </Router>
    </ThemeProvider>
  );
}

export default App;
*/