import React from 'react';
import { Box, Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Grid } from '@mui/material';

interface SaleDetail {
    idSales: number;
    productName: string;
    productType: string;
    saleDate: Date;
    cost: number;
    quantity: number;
    payment: boolean;
    comision: number;
}

interface CombinedUserData {
    idUser: number;
    firstName: string;
    lastName: string;
    totalServices: number;
    totalProducts: number;
    totalServicesValue: number;
    totalProductsValue: number;
    salesDetails: SaleDetail[];
    comision: number;
    ci: number;
    celular: number;
    sueldo: number;
    diasTrabajados: number;
    fechaInicio: Date;
    totalAPagar: number;
    sueldoOriginal: number;
    diasTrabajarOriginal: number;
    valorAdelantos: number;
}

interface DerechoSueldosProps {
    selectedUser: number | null;
    combinedData: CombinedUserData[];
    handlePayment: () => void;
}

const DerechoSueldos: React.FC<DerechoSueldosProps> = ({ selectedUser, combinedData, handlePayment }) => {
    const selectedUserData = combinedData.find(user => user.idUser === selectedUser);

    // Agrupar ventas por fecha
    const groupedSalesDetails = selectedUserData?.salesDetails.reduce((acc, sale) => {
        const saleDate = new Date(sale.saleDate).toLocaleDateString();
        if (!acc[saleDate]) {
            acc[saleDate] = [];
        }
        acc[saleDate].push(sale);
        return acc;
    }, {} as Record<string, SaleDetail[]>);
    console.log("-----> selectedUserData: ", selectedUserData)
    return (
        <Box sx={{ p: 2 }}>
            {selectedUser ? 
                <Typography variant="h6" sx={{ mb: 2, textTransform: 'uppercase', color: 'blue' }}>
                    Detalles de Sueldo
                </Typography>:
                <Typography variant="h6" sx={{ mb: 2, textTransform: 'uppercase', color: 'blue' }}>
                    Seleccione un usuario
                </Typography>
            }


            {selectedUser && selectedUserData ? (
                <>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="body1"><strong>Nombre:</strong> {selectedUserData.firstName} {selectedUserData.lastName}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="body1"><strong>CI:</strong> {selectedUserData.ci}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="body1"><strong>Celular:</strong> {selectedUserData.celular}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="body1"><strong>Días Trabajados:</strong> {selectedUserData.diasTrabajados}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="body1"><strong>Sueldo:</strong> {selectedUserData.sueldoOriginal} por {selectedUserData.diasTrabajarOriginal} días</Typography>
                    </Grid>
                </Grid>

                    <TableContainer component={Paper} sx={{ my: 2 }}>
                        <Table aria-label="Detalles de ventas">
                        <TableHead>
                            <TableRow>
                                <TableCell><strong>FECHA</strong></TableCell>
                                <TableCell><strong>PRODUCTOS</strong></TableCell>
                                <TableCell><strong>PRECIO TOTAL</strong></TableCell>
                                <TableCell><strong>COMISIÓN ACUMULADA</strong></TableCell>
                            </TableRow>
                        </TableHead>

                            <TableBody>
                                {groupedSalesDetails && Object.entries(groupedSalesDetails).map(([date, sales]) => (
                                    <TableRow key={date}>
                                        <TableCell>{date}</TableCell>
                                        <TableCell>
                                            {sales.map((detail, index) => (
                                                <div key={index}>{detail.productName} (Cant = {detail.quantity})</div>
                                            ))}
                                        </TableCell>
                                        <TableCell>
                                            {sales.reduce((total, detail) => total + detail.cost, 0).toFixed(2)} Bs
                                        </TableCell>
                                        <TableCell>
                                            {sales.reduce((total, detail) => total + detail.comision, 0).toFixed(2)} Bs
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    
                    <Typography variant="h6" sx={{ mt: 2 }}>TOTAL COMISIONES: {selectedUserData.comision.toFixed(2)} Bs</Typography>
                    <Typography variant="h6" sx={{ mt: 2 }}>SUELDO: {selectedUserData.sueldo.toFixed(2)} Bs</Typography>
                    <Typography variant="h6" sx={{ mt: 2, color: 'orange' }}>ADELANTOS TOTALES: {selectedUserData.valorAdelantos.toFixed(2)} Bs</Typography>
                    <Typography variant="h6" sx={{ mt: 2 }}>TOTAL A PAGAR: {selectedUserData.totalAPagar.toFixed(2)} Bs</Typography>

                    <Button variant="contained" color="primary" onClick={handlePayment}>
                        Procesar Pago
                    </Button>
                </>
            ) : (
                <Typography>No hay usuario seleccionado o no se encontraron datos.</Typography>
            )}
        </Box>
    );
};

export default DerechoSueldos;
