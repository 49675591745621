import React, { useState, useEffect, useContext } from 'react';
import { TextField, Grid, InputAdornment, Box, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from '@mui/material';
import PhotoIcon from '@mui/icons-material/Photo';
import InventoryContext from '../../../../contexts/InventoryContext';

interface AgregarProductoIzquierdaServicioProps {
    datoDetalles: string;
    setDatoDetalles: React.Dispatch<React.SetStateAction<string>>;
    datoCostoCompra: number;
    setDatoCostoCompra: React.Dispatch<React.SetStateAction<number>>;
    datoCostoVenta: number;
    setDatoCostoVenta: React.Dispatch<React.SetStateAction<number>>;
    datoImagen: string;
    setDatoImagen: React.Dispatch<React.SetStateAction<string>>;
    handleTextFieldChange: (
        setFunction: React.Dispatch<React.SetStateAction<string>>,
        transformFunction?: (value: string) => string
    ) => (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleTextFieldBlur: (
        setFunction: React.Dispatch<React.SetStateAction<string>>,
        transformFunction: (value: string) => string
    ) => (event: React.FocusEvent<HTMLInputElement>) => void;
    handleNumberFieldChange: (
        setFunction: React.Dispatch<React.SetStateAction<number>>,
        transformFunction?: (value: string) => number
    ) => (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleNumberFieldBlur: (
        setFunction: React.Dispatch<React.SetStateAction<number>>,
        transformFunction: (value: string) => number
    ) => (event: React.FocusEvent<HTMLInputElement>) => void;
    formatName: (name: string) => string;
    formatAmount: (amount: string) => number;
    imagesRutas: string[];
    descripcionImagenes: string[];
    errors: { [key: string]: boolean };
}

const AgregarProductoIzquierdaServicio: React.FC<AgregarProductoIzquierdaServicioProps> = ({
    datoDetalles,
    setDatoDetalles,
    datoCostoCompra,
    setDatoCostoCompra,
    datoCostoVenta,
    setDatoCostoVenta,
    datoImagen,
    setDatoImagen,
    handleTextFieldChange,
    handleTextFieldBlur,
    handleNumberFieldChange,
    handleNumberFieldBlur,
    formatName,
    formatAmount,
    imagesRutas,
    descripcionImagenes,
    errors
}) => {
    const context = useContext(InventoryContext);

    if (!context) {
      throw new Error('UserList must be used within an InventoryProvider');
    }
    const { tableData, deleteItem, addItem, updateItem } = context;

    
    const [open, setOpen] = useState(false);
    const [filtro, setFiltro] = useState('');
    const [imagenesFiltradas, setImagenesFiltradas] = useState<string[]>([]);
    const [imagenesFiltradasNombre, setImagenesFiltradasNombre] = useState<string[]>([]);
    const [localCosto, setLocalCosto] = useState(datoCostoCompra.toString());
    const [localPrecioVenta, setLocalPrecioVenta] = useState(datoCostoVenta.toString());

    
    useEffect(() => {
        const filtradas = imagesRutas.filter((ruta, index) =>
            descripcionImagenes[index].toLowerCase().includes(filtro.toLowerCase())
        );
        const nombresFiltrados = descripcionImagenes.filter((descripcion) =>
            descripcion.toLowerCase().includes(filtro.toLowerCase())
        );
        setImagenesFiltradas(filtradas);
        setImagenesFiltradasNombre(nombresFiltrados);
    }, [filtro, imagesRutas, descripcionImagenes]);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setFiltro(""); 
    };

    const handleListItemClick = (imgRuta: string) => {
        setDatoImagen(imgRuta);
        setOpen(false);
        setFiltro("");
    };

    return (
        <Grid item xs={12} md={6}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label="Descripcion de servicio"
                        variant="outlined"
                        value={datoDetalles}
                        onChange={handleTextFieldChange(setDatoDetalles, formatName)}
                        error={errors.datoDetalles}
                        helperText={errors.datoDetalles ? "Este campo es obligatorio" : ""}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label="Costo"
                        variant="outlined"
                        value={localCosto === "0" || localCosto === "0.00" ? "" : localCosto} // Usar estado local
                        onChange={(e) => setLocalCosto(e.target.value)} // Permite cualquier entrada
                        onBlur={() => {
                            let formattedValue = parseFloat(localCosto.replace(",", "."));
                            if (isNaN(formattedValue)) formattedValue = 0;
                            setDatoCostoCompra(formattedValue); // Actualizar el padre
                            setLocalCosto(formattedValue.toFixed(2)); // Formatear el valor local
                        }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end" style={{ color: 'gray' }}>
                                    Bs
                                </InputAdornment>
                            ),
                        }}
                        error={errors.datoCostoCompra}
                        helperText={errors.datoCostoCompra ? "Este campo es obligatorio" : ""}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label="Precio Venta"
                        variant="outlined"
                        value={localPrecioVenta === "0" || localPrecioVenta === "0.00" ? "" : localPrecioVenta} // Usar estado local
                        onChange={(e) => setLocalPrecioVenta(e.target.value)} // Permite cualquier entrada
                        onBlur={() => {
                            let formattedValue = parseFloat(localPrecioVenta.replace(",", "."));
                            if (isNaN(formattedValue)) formattedValue = 0;
                            setDatoCostoVenta(formattedValue); // Actualizar el padre
                            setLocalPrecioVenta(formattedValue.toFixed(2)); // Formatear el valor local
                        }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end" style={{ color: 'gray' }}>
                                    Bs
                                </InputAdornment>
                            ),
                        }}
                        error={errors.datoCostoVenta}
                        helperText={errors.datoCostoVenta ? "Este campo es obligatorio" : ""}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Box style={{ textAlign: 'center' }}> {/* Centrar el contenido dentro de Box */}
                        <IconButton onClick={handleClickOpen} color="primary" aria-label="upload picture" component="span">
                        <PhotoIcon />
                        {datoImagen && (
                            <img src={datoImagen} alt="Seleccionada" style={{ width: 50, marginLeft: 10, borderRadius: '4px', border: '2px solid #ccc' }} /> 
                        )}
                        </IconButton>
                        <Dialog open={open} onClose={handleClose} fullWidth>
  <DialogTitle style={{ fontWeight: 'bold', textAlign: 'center' }}>SELEECIONAR IMAGEN</DialogTitle>
  <DialogContent>
    <Box style={{ position: 'sticky', top: 0, zIndex: 10, backgroundColor: 'white' }}> 
      <TextField
        margin="dense"
        id="search"
        label="Buscar"
        type="text"
        fullWidth
        variant="outlined"
        className="buscar-input"
        onChange={(e) => setFiltro(e.target.value)}
        style={{ marginBottom: '20px' }}
      />
    </Box>
    <Grid container spacing={2} justifyContent="center">
      {imagenesFiltradas.map((imgRuta, index) => (
        <Grid item xs={6} key={imgRuta}>
          <Box
            onClick={() => handleListItemClick(imgRuta)}
            role="button"
            sx={{ textAlign: 'center', cursor: 'pointer' }}
            style={{ border: '1px solid #ccc', padding: '10px', borderRadius: '8px' }}
          >
            <Typography variant="body2" style={{ fontWeight: 'bold', marginBottom: '10px' }}>
              {imagenesFiltradasNombre[index]}
            </Typography>
            <img
              src={imgRuta}
              alt={imagenesFiltradasNombre[index]}
              style={{ width: '100%', maxWidth: '100px', height: '100px', borderRadius: '4px' }}
            />
          </Box>
        </Grid>
      ))}
    </Grid>
  </DialogContent>
  <DialogActions style={{ justifyContent: 'center' }}>
    <Button onClick={handleClose} color="primary" style={{ fontWeight: 'bold' }}>
      Cancelar
    </Button>
  </DialogActions>
</Dialog>

                    </Box>
                    </Grid>

            </Grid>
        </Grid>
    );
};

export default AgregarProductoIzquierdaServicio;
