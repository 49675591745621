import React, { useState } from 'react';
import { Box, Button } from '@mui/material';
import { NavLink } from 'react-router-dom';
import AgregarOperario from './AgregarOperario/AgregarOperario';
import AgregarProducto from './AgregarPS/AgregarProducto';
import SueldosOperario from './Sueldos/SueldosOperario';
import OpcionesGrid from '../../../general/OpcionesGrid';

const Administracion: React.FC = () => {
  const [seccionActiva, setSeccionActiva] = useState<string | null>(null);
  const [enVistaSeleccion, setEnVistaSeleccion] = useState(true);

  const handleSeleccionarSeccion = (seccion: string) => {
    setSeccionActiva(seccion);
    setEnVistaSeleccion(false);
  };

  const handleVolverASeleccion = () => {
    setSeccionActiva(null);
    setEnVistaSeleccion(true);
  };

  const renderizarSeccion = () => {
    switch (seccionActiva) {
      case 'agregarOperario':
        return <AgregarOperario />;
      case 'agregarpc':
        return <AgregarProducto />;
      case 'sueldos':
        return <SueldosOperario />;
      default:
        return null;
    }
  };

  const opciones = [
    {
      titulo: 'Personal',
      imagen: '/images/opciones/25.png',
      onClick: () => handleSeleccionarSeccion('agregarOperario'),
    },
    {
      titulo: 'GESTIÓN P/S',
      imagen: '/images/opciones/26.png',
      onClick: () => handleSeleccionarSeccion('agregarpc'),
    },
    {
      titulo: 'Sueldos',
      imagen: '/images/opciones/27.png',
      onClick: () => handleSeleccionarSeccion('sueldos'),
    },
  ];

  return (
    <Box className="gestion-container" sx={{ marginTop: 0, padding: 0 }}>
      {enVistaSeleccion ? (
        // Vista inicial usando OpcionesGrid con un array de opciones
        <OpcionesGrid opciones={opciones} />
      ) : (
        // Renderizado de la sección activa
        <Box>
          {renderizarSeccion()}
          <Button onClick={handleVolverASeleccion} className="bottom-icon-button">
            <img src="/images/iconos/flecha_atras_negro.png" alt="Back" className="bottom-icon" />
          </Button>
        </Box>
      )}

      {/* Botones de navegación en la vista inicial */}
      {enVistaSeleccion && (
        <Box
          className="bottom-row"
          sx={{
            display: 'flex',
            justifyContent: 'center',
            gap: 2,
            padding: 2,
            marginTop: 2,
          }}
        >
          <Button component={NavLink} to="/salones" className="bottom-icon-button">
            <img src="/images/iconos/flecha_atras_negro.png" alt="Back" className="bottom-icon" />
          </Button>
          <Button component={NavLink} to="/" className="bottom-icon-button">
            <img src="/images/iconos/home_back.png" alt="Home" className="bottom-icon" />
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default Administracion;
