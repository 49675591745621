import React, { useEffect, useState } from 'react';
import {
  TextField,
  Button,
  IconButton,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Tooltip,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import AddCommentIcon from '@mui/icons-material/AddComment';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import QrCodeIcon from '@mui/icons-material/QrCode';
import PrintIcon from '@mui/icons-material/Print';
import { UserAttributes } from '../../../../interfaces/models';
import { generateFormattedTicket } from '../../../../utils/pdfUtils';

interface Ticket {
  user_id_sale_made: number;
  id_service_product: number;
  oper: string;
  descripcion: string;
  cantidad: number;
  precioUnitario: number;
  tipoComision: number;
  comisionActiva: boolean;
  costoComisionAPagar: number;
  cantidadEnvase: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  textField: {
    '& .MuiInputLabel-outlined': {
      transform: 'translate(14px, 10px) scale(1)',
      backgroundColor: 'white',
      padding: '0 4px',
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(14px, -6px) scale(0.75)',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: theme.palette.primary.main,
      },
      '&:hover fieldset': {
        borderColor: theme.palette.primary.main,
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.primary.main,
      },
    },
  },
}));

interface VentaCentroProps {
  handleOpenDialog: () => void;
  handleOpenConfirmDialog: () => void;
  openConfirmDialog: boolean;
  handleCloseConfirmDialog: () => void;
  grandTotal: number;
  handleConfirmVenta: () => void;
  openDialog: boolean;
  handleCloseDialog: () => void;
  observaciones: string;
  setObservaciones: (value: string) => void;
  handleSaveObservaciones: () => void;
  tickets: Ticket[];
  usuarios: UserAttributes[];
  calculateGrandTotal: (data: Ticket[]) => number;
}

export const VentaCentro: React.FC<VentaCentroProps> = ({
  handleOpenDialog,
  handleOpenConfirmDialog,
  openConfirmDialog,
  handleCloseConfirmDialog,
  grandTotal,
  handleConfirmVenta,
  openDialog,
  handleCloseDialog,
  observaciones,
  setObservaciones,
  handleSaveObservaciones,
  tickets,
  usuarios,
  calculateGrandTotal,
}) => {
  const [cobrar, setCobrar] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [openQRDialog, setOpenQRDialog] = useState<boolean>(false); // Nuevo estado para QR

  const handleOpenQRDialog = () => {
    setOpenQRDialog(true); // Abrir diálogo de QR
  };

  const handleCloseQRDialog = () => {
    setOpenQRDialog(false); // Cerrar diálogo de QR
  };

  const handleChangeCobrar = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCobrar(event.target.value);
    if (parseFloat(event.target.value) >= grandTotal) {
      setError('');
    }
  };

  const handleValidationAndConfirm = () => {
    if (!cobrar || parseFloat(cobrar) < grandTotal) {
      setError('El monto a cobrar debe ser mayor o igual al total.');
      return;
    }
    handleConfirmVenta();
  };

  const cambio = parseFloat(cobrar) - grandTotal;

  useEffect(() => {
    if (openConfirmDialog || openQRDialog) {
      setCobrar('');
      setError('');
    }
  }, [openConfirmDialog, openQRDialog]);

  return (
    <Grid container direction="column" spacing={3} sx={{ padding: 2 }}>
      {/* Íconos en la parte superior, centrados y con mayor espaciado */}
      <Grid item>
        <Grid container justifyContent="center" spacing={3}>
          <Tooltip title="Agregar Observaciones">
            <IconButton color="primary" onClick={handleOpenDialog}>
              <AddCommentIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Pagar en Efectivo">
            <IconButton color="primary" onClick={handleOpenConfirmDialog}>
              <AttachMoneyIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Pagar con QR">
            <IconButton color="primary" onClick={handleOpenQRDialog}>
              <QrCodeIcon />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>

      {/* Contenido principal debajo de los íconos */}
      <Grid item>
        {/* Diálogo de Confirmar Venta de Ticket */}
        <Dialog open={openConfirmDialog} onClose={handleCloseConfirmDialog} maxWidth="sm" fullWidth>
          <DialogTitle style={{ fontWeight: 'bold', textAlign: 'center', textTransform: 'uppercase' }}>
            Confirmar Venta de Ticket
          </DialogTitle>
          <Box p={3}>
            <Box mb={3}>
              <TextField
                label="Pago"
                value={cobrar}
                onChange={handleChangeCobrar}
                fullWidth
                variant="outlined"
                margin="normal"
                error={!!error}
                helperText={error}
              />
            </Box>
            <Box mb={3}>
              <TextField
                label="Cobrar"
                value={grandTotal.toFixed(2) + " Bs"}
                disabled
                fullWidth
                variant="outlined"
                margin="normal"
                InputLabelProps={{ style: { fontWeight: 'bold' } }}
                InputProps={{
                  style: { fontWeight: 'bold', color: '#FF8C00', fontSize: '1.5rem' },
                }}
              />
            </Box>
            <Box mb={3}>
              <TextField
                label="Cambio"
                value={isNaN(cambio) ? '' : cambio.toFixed(2) + " Bs"}
                disabled
                fullWidth
                variant="outlined"
                margin="normal"
                InputLabelProps={{ style: { fontWeight: 'bold' } }}
                InputProps={{ style: { fontWeight: 'bold', color: 'black', fontSize: '1.5rem' } }}
              />
            </Box>

            <DialogActions>
              <Button onClick={handleCloseConfirmDialog}>Cancelar</Button>
              <Button
                onClick={() => {
                  handleValidationAndConfirm();
                  handleOpenDialog();
                }}
              >
                Confirmar
              </Button>
              <Tooltip title="Imprimir Ticket">
                <IconButton
                  color="secondary"
                  size="large"
                  onClick={() => generateFormattedTicket(usuarios, tickets, calculateGrandTotal(tickets), observaciones)}
                >
                  <PrintIcon />
                </IconButton>
              </Tooltip>
            </DialogActions>
          </Box>
        </Dialog>

        {/* Nuevo diálogo de Pago con QR */}
        <Dialog open={openQRDialog} onClose={handleCloseQRDialog} maxWidth="sm" fullWidth>
          <DialogTitle style={{ fontWeight: 'bold', textAlign: 'center', textTransform: 'uppercase' }}>
            Confirmar Pago con QR
          </DialogTitle>
          <DialogContent>
            <Box p={3} style={{ textAlign: 'center' }}>
              <img src="/qr-code-placeholder.webp" alt="Código QR" style={{ width: '200px', height: '200px' }} />
            </Box>
          </DialogContent>

          <DialogTitle style={{ fontWeight: 'bold', textAlign: 'center', textTransform: 'uppercase' }}>
              Total a pagar: {grandTotal.toFixed(2) + " Bs"}
            </DialogTitle>
            <DialogActions sx={{ justifyContent: 'center' }}>
              <Button onClick={handleCloseQRDialog}>Cancelar</Button>
              <Button
                onClick={() => {
                  handleConfirmVenta(); // Confirmar la venta con QR
                  handleOpenDialog();   // Abrir diálogo de observaciones
                }}
              >
                Confirmar
              </Button>
            </DialogActions>

        </Dialog>

        {/* Diálogo de Agregar Observaciones */}
        <Dialog open={openDialog} onClose={() => {
          setObservaciones("");
          handleCloseDialog();
        }} maxWidth="sm" fullWidth>
          <DialogTitle style={{ fontWeight: 'bold', textAlign: 'center', textTransform: 'uppercase' }}>
            Agregar Observaciones
          </DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="observaciones"
              label="Observaciones"
              type="text"
              fullWidth
              variant="outlined"
              value={observaciones}
              onChange={(e) => setObservaciones(e.target.value)}
              sx={{ marginBottom: 3 }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => { setObservaciones(""); handleCloseDialog(); }}>Cancelar</Button>
            <Button onClick={handleSaveObservaciones}>Guardar</Button>
          </DialogActions>
        </Dialog>
      </Grid>
    </Grid>
  );
};
